import React from "react";
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

interface breadcrumbsProps {
  link: string;
}

const MobileApplicationDevelopment: React.FC<breadcrumbsProps> = ({ link }) => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "What is mobile application development?",
      answer: "Mobile application development is a multifaceted process dedicated to creating software specifically for mobile devices, including smartphones and tablets. At Unique IT Solution, we follow a series of well-defined stages: initial design, detailed development, thorough testing, and strategic deployment. Our goal is to ensure that the applications we build deliver a flawless experience on both iOS and Android platforms, integrating seamlessly with devices and providing exceptional usability. With our expertise, we guarantee top-notch performance and user satisfaction.",
    },
    {
      id: "panel2",
      question: "Why should I choose Unique IT Solution for mobile app development?",
      answer: "Unique IT Solution is your go-to for custom mobile app development that aligns with your business goals. We manage every step of the process, from concept creation to final release, ensuring your app meets all your specifications and provides a seamless experience for users."
    },
    {
      id: "panel3",
      question: "How do you ensure the quality of the mobile application?",
      answer: "To guarantee a reliable and user-friendly mobile app, Unique IT Solution employs rigorous quality assurance practices. Our testing phases include functional, performance, and user acceptance tests, ensuring your app meets the highest standards."
    },
    {
      id: "panel4",
      question: "What is the cost of developing a mobile application?",
      answer: "Developing a mobile app involves various cost factors, including its complexity, features, and the platforms it will be available on. Unique IT Solution ensures precise cost estimates tailored to your project, derived from a thorough analysis of your specific needs and objectives."
    },
    {
      id: "panel5",
      question: "What support do you offer after the app is launched?",
      answer: "With Unique IT Solution, you receive unparalleled post-launch support designed to keep your mobile app running smoothly. Our dedicated team handles everything from fixing bugs to rolling out updates and performing essential maintenance. We focus on continual improvement and user satisfaction, making sure your app evolves with your needs."
    },
  ]
  return (
    <>
      <Helmet>
        <title>Mobile Application Development | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner service-details-banner bg-light-purple"
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <div className="left">
              <Breadcrumbs
                separator={
                  <img
                    loading="lazy"
                    src={images.CaretRight}
                    alt="Caret Right"
                  />
                }
                data-aos="fade-up"
              >
                <Link to={`/${link}`} title={link}>
                  {link.charAt(0).toUpperCase() + link.slice(1)}
                </Link>
                <Typography>Mobile Application Development</Typography>
              </Breadcrumbs>
              <Typography
                component="h2"
                variant="h2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Mobile Application Development
              </Typography>
              <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                <Button
                  variant="contained"
                  color="primary"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  title="Get A Quote"
                >
                  <span className="text">Get A Quote</span>
                </Button>
              </Link>
            </div>
            <div className="right" data-aos="fade-up">
              <img
                loading="lazy"
                src={images.MobileApplicationDevelopmentBannerImage}
                alt="Mobile Application Development"
                width="483"
                height="473"
              />
            </div>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        <section className="service-details-content">
          <Typography component="p" variant="body1" data-aos="fade-up">
            We design robust mobile applications that can be easily integrated
            with your personalised software. Thus, it becomes easy for you to
            maintain your communication channel and the work gets streamlined.
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            align="center"
            data-aos="fade-up"
            className="pb-7"
          >
            How We Build Mobile Apps
          </Typography>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.RequirementGathering}
                  alt="Strong business analysis and Requirements Gathering"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Strong business analysis and Requirements Gathering
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  We begin our mobile application development journey with a
                  strong focus on business analysis. In this step, we do a
                  thorough review of your company objectives, specifics about
                  your target audience, and market demands. It is done through
                  initial paper sketches, which are used majorly in visualizing
                  and refining the concept of the application, resulting from
                  understanding every detail about your company’s needs. This
                  guarantees that each subsequent step in design and development
                  aligns closely with your strategic goals, thus enhancing the
                  effectiveness of the entire mobile application suite.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Development and Functionality
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  When developing an application, we start by focusing on the
                  most important features and backend functions in several
                  phases, improving it step-by-step.
                  <br />
                  <br />
                  The features should improve performance, handle data well, and
                  meet requirements, while working smoothly with backend
                  systems.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.DevelopmentAndTesting}
                  alt="Development and Functionality"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.QualityAssurance}
                  alt="Quality Assurance and Integration"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Quality Assurance and Integration
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Application functionality must also be tested rigorously to
                  ensure that it operates effectively regardless of the device
                  or platform used.
                  <br />
                  <br />
                  Integrating backend systems, APIs, and databases maintains
                  data integrity and creates a wholesome experience devoid of UI
                  components.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Deployment, Support, and Continuous Improvement
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Make sure that your launch runs smoothly and that you waste
                  minimum time by creating good strategies for deployment and
                  executing them.
                  <br />
                  <br />
                  Ensure technical issues are resolved, and application
                  performance is optimized after launching through continued
                  support and maintenance.
                  <br />
                  <br />
                  Keep updating the application based on user feedback,
                  analytics trends, and emerging technologies to address
                  changing business needs and industry standards.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.DeploymentSupport}
                  alt="Deployment, Support, and Continuous Improvement"
                />
              </div>
            </Grid>
          </Grid>
        </section>
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default MobileApplicationDevelopment;
