import React from "react";
import { Card, CardContent, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import images from "../../assets/images";

interface industryCardProps {
  bgImage: string;
  title: string;
  link: string;
  from: string;
}

const IndustryCard: React.FC<industryCardProps> = ({
  bgImage,
  title,
  link,
  from,
}) => {
  return (
    <>
      <Card
        className="industry-card"
        sx={{
          height: "540px",
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <CardContent>
          <Link
            to={`${process.env.PUBLIC_URL}${link}`}
            state={{ from: from }}
            title={title}
          >
            <Typography variant="subtitle1" component="p">
              {title}
            </Typography>
            <IconButton size="small">
              <img
                loading="lazy"
                src={images.RightArrowWhite}
                alt="Right Arrow"
              />
            </IconButton>
          </Link>
        </CardContent>
      </Card>
    </>
  );
};

export default IndustryCard;
