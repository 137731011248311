import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import Aos from "aos";
import React, { useEffect } from "react";
import images from "../../assets/images";
// import { Link } from "react-router-dom";

interface FAQItem {
  id: string;
  question: string;
  answer: string;
  link?: string[];
  parameter?: { [key: string]: string }[];
}
interface FAQProps {
  faqData: FAQItem[];
}

const ExpandMoreSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_147_2482)">
      <path
        d="M19.5 9L12 16.5L4.5 9"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_147_2482">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const FAQ: React.FC<FAQProps> = ({ faqData }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    Aos.init({
      duration: 700,
      once: true,
    });
  }, []);

  return (
    <>
      <section className="faq mb-9 outer-card" data-aos="fade-in">
        <Container maxWidth="xl">
          <Grid container spacing={5}>
            <Grid item lg={5}>
              <div
                className="image-wrapper"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <img src={images.FAQ} alt="FAQ" loading="lazy" />
              </div>
            </Grid>
            <Grid item lg={7}>
              <Typography
                component="h3"
                variant="h3"
                className="pb-5"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                FAQs
              </Typography>
              <div data-aos="fade-up" data-aos-delay="200">
                {faqData.map((faq) => (
                  <Accordion
                    key={faq.id}
                    expanded={expanded === faq.id}
                    onChange={handleChange(faq.id)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreSvg />}>
                      {faq.question}
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{faq.answer}</p>
                      {faq.link && faq.link.map((link, index) => (
                        <div key={index}>
                          <li>
                            <a href={link} target="_blank" rel="noopener noreferrer">
                              {link}
                            </a>
                          </li>
                        </div>
                      ))}
                      {faq.parameter && faq.parameter.map((paramObj, index) => (
                        <div key={index}>
                          {Object.entries(paramObj).map(([key, value]) => (
                            <div key={key}>
                              <li><span style={{ fontWeight: 'bold' }}>{key}:</span> {value}</li>
                            </div>
                          ))}
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
              {/* <div data-aos="fade-up" data-aos-delay="200">
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreSvg />}>
                    Why choose unique IT solution as service provider?
                  </AccordionSummary>
                  <AccordionDetails>
                    Unique IT Solution delivers custom, cutting-edge technology
                    solutions tailored to your needs. Our expertise and
                    customer-centric approach ensure innovative, cost-effective
                    services that drive growth and optimize performance.
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreSvg />}>
                    Which technology we use for development?
                  </AccordionSummary>
                  <AccordionDetails>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreSvg />}>
                    How would you provide custom software development?
                  </AccordionSummary>
                  <AccordionDetails>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreSvg />}>
                    What about maintence?
                  </AccordionSummary>
                  <AccordionDetails>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreSvg />}>
                    How to contact us?
                  </AccordionSummary>
                  <AccordionDetails>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </AccordionDetails>
                </Accordion>
              </div> */}
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default FAQ;
