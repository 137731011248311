import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

interface breadcrumbsProps {
  link: string;
}

const WorkFlowAutomation: React.FC<breadcrumbsProps> = ({ link }) => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "What is Work Flow Automation?",
      answer: "Workflow Automation uses advanced technology to automate business tasks and processes, streamlining operations and boosting efficiency. With Unique IT Solution, you can focus on strategic goals while we handle the repetitive tasks through our cutting-edge automation systems.",
    },
    {
      id: "panel2",
      question: "How does Unique IT Solution approach Work Flow Automation?",
      answer: "Unique IT Solution takes a client-focused approach to Work Flow Automation. We start by carefully assessing your current workflows to determine areas for enhancement. From there, we develop and deploy tailored automation solutions that fit your specific business requirements, ensuring a smooth integration process and enhanced operational efficiency."

    },
    {
      id: "panel3",
      question: "How can Work Flow Automation benefit my business?",
      answer: "Work Flow Automation offers several benefits, including increased productivity, reduced operational costs, and minimized human errors. With automation, you can streamline processes, improve accuracy, and free up valuable time for your team to focus on high-value tasks. Unique IT Solution provides tailored automation solutions that align with your specific business needs."
    },
    {
      id: "panel4",
      question: "Is Workflow Automation scalable for growing businesses?",
      answer: "Yes, Workflow Automation is highly scalable. Unique IT Solution ensures that the automation solutions we implement can grow with your business, accommodating increased volumes and evolving processes without compromising performance."
    },
    {
      id: "panel5",
      question: "Can Workflow Automation improve customer service?",
      answer: " Absolutely. By automating customer interactions and service processes, you can provide faster and more accurate responses. Unique IT Solution creates automation solutions that enhance customer experience and streamline service delivery."
    },
  ]
  return (
    <>
      <Helmet>
        <title>Work Flow Automation | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner service-details-banner bg-light-purple"
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <div className="left">
              <Breadcrumbs
                separator={
                  <img
                    loading="lazy"
                    src={images.CaretRight}
                    alt="Caret Right"
                  />
                }
                data-aos="fade-up"
              >
                <Link to={`/${link}`} title={link}>
                  {link.charAt(0).toUpperCase() + link.slice(1)}
                </Link>
                <Typography>Work Flow Automation</Typography>
              </Breadcrumbs>
              <Typography
                component="h2"
                variant="h2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Work Flow Automation
              </Typography>
              <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                <Button
                  variant="contained"
                  color="primary"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  title="Get A Quote"
                >
                  <span className="text">Get A Quote</span>
                </Button>
              </Link>
            </div>
            <div className="right" data-aos="fade-up">
              <img
                loading="lazy"
                src={images.WorkFlowAutomationBannerImage}
                alt="Work Flow Automation"
                width="483"
                height="473"
              />
            </div>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        <section className="service-details-content">
          <Typography component="p" variant="body1" data-aos="fade-up">
            Our company, Unique IT Solution, offers a complete workflow
            automation solution that improves business processes and enables
            growth. These systems are advanced to fit each client, hence
            ensuring that businesses reach their goals with minimum effort and
            cost.
          </Typography>
          <Typography
            component="h3"
            align="center"
            variant="h3"
            className="pb-7"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Our Expertise Includes
          </Typography>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.CustomerServiceSupport}
                  alt="Customer Service and Support"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Customer Service and Support
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Our first step is to understand your needs to deliver the
                  utmost customer satisfaction. For this, we create a paper plan
                  and analyse the strengths and weaknesses. This plan helps us
                  identify your problems. In most cases, businesses lack time
                  because they are busy doing repetitive tasks. So, if tasks are
                  repetitive and time-consuming, we automate the repetitive
                  duties and helpdesk management work to reduce resolution
                  periods and improve customer satisfaction.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Automating Repetitive Tasks
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  className="pb-4"
                  data-aos-delay="100"
                >
                  Unique IT Solution specifically excels at automating
                  monotonous duties to increase productivity while minimizing
                  mistakes. Our office workflow automation services involve
                  activities such as scheduling, reminding, or generating
                  reports, which generally saves time and ensures accuracy. For
                  a seamless process, we develop customized workflows that take
                  care of approvals, notifications, task assignments, and
                  escalations. A recurring task scheduler helps maintain
                  regularity in handling daily work, thus enhancing
                  dependability across the business.
                </Typography>
                {/* <Grid
                  container
                  spacing={3}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Administrative Automation
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Workflow Automation Tools
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Recurring Task Scheduling
                      </Typography>
                    </div>
                  </Grid>
                </Grid> */}
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.AutomatingRepetitiveTasks}
                  alt="Automating Repetitive Tasks"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.DataEntry}
                  alt="Data Entry and Management"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Data Entry and Management
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  className="pb-4"
                  data-aos-delay="100"
                >
                  At unique it solution, we have automated data entry and
                  management systems that assist in reducing inaccuracies by
                  humans and increasing precision in data entry. Our solutions
                  include automated data entry to make sure there is no mistake
                  during information processing. We have implemented strong
                  systems for real-time data synchronization across various
                  platforms, thereby guaranteeing uniformity and accessibility
                  of information from different sources when needed. In addition
                  to this, our automatic procedures ensure high-quality data
                  validation/correction/cleansing, which allows the organization
                  to make informed choices about its programs or services and
                  operate more efficiently.
                </Typography>
                {/* <Grid
                  container
                  spacing={3}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Accurate Data Input
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Data Synchronization
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Data Validation and Cleansing
                      </Typography>
                    </div>
                  </Grid>
                </Grid> */}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Automatic Error Detection and System Reset
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  className="pb-4"
                  data-aos-delay="100"
                >
                  At unique it solution, we specialize in automatic error
                  detection and system resetting. It helps in maintaining
                  operational continuity with minimum disruptions. We provide
                  continuous error monitoring services to identify potential
                  issues proactively. Further, we have automated error reporting
                  and logging to facilitate quick resolution of problems, hence
                  improving system reliability. Besides, we have put into place
                  recovery systems that automatically minimize downtime while
                  ensuring smooth business continuation, thereby boosting
                  overall operational resilience.
                </Typography>
                {/* <Grid
                  container
                  spacing={3}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Error Monitoring
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Error Reporting and Logging
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        System Recovery
                      </Typography>
                    </div>
                  </Grid>
                </Grid> */}
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.ErrorDetection}
                  alt="Automatic Error Detection and System Reset"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.SupplyChain}
                  alt="Supply Chain and Logistics"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Supply Chain and Logistics
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  className="pb-4"
                  data-aos-delay="100"
                >
                  unique it solution specializes in automating supply chain and
                  logistics for optimized operations. We improve efficiency
                  throughout the supply chain by automating procurement,
                  production, inventory management, and more. Therefore, we
                  enhance overall operational efficiency by streamlining
                  logistics planning and execution. Furthermore, we boost
                  shipment tracking and visibility across the supply chain so
                  that deliveries are made on time with informed
                  decision-making. <br />
                  <br />
                  Our solutions help businesses achieve their goals faster and
                  more economically than ever before.
                </Typography>
                {/* <Grid
                  container
                  spacing={3}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Supply Chain Optimization
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Logistics Planning and Execution
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Shipment Tracking and Visibility
                      </Typography>
                    </div>
                  </Grid>
                </Grid> */}
              </div>
            </Grid>
          </Grid>
        </section>
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default WorkFlowAutomation;
