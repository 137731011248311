import React, { useEffect, useState } from "react";

import "./App.css";
import AppRouter from "./App.routing";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./App.theme";
import SiteLoader from "./components/SiteLoader";

function App() {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const incrementProgress = () => {

      setProgress((prev) => (prev < 100 ? prev + 1 : prev));
    };

    const interval = setInterval(incrementProgress, 100);

    const handleLoad = () => {
      clearInterval(interval);
      setProgress(100);
      setTimeout(() => setLoading(false), 500);
    };

    // window.addEventListener("load", handleLoad);
    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      clearInterval(interval);
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <div className="App">
      
        <ThemeProvider theme={theme}>
          {loading ? <SiteLoader progress={progress} /> : <AppRouter />}
        </ThemeProvider>
      
    </div>
  );
}

export default App;
