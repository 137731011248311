import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

interface breadcrumbsProps {
  link: string;
}

const CustomSoftwareDevelopment: React.FC<breadcrumbsProps> = ({ link }) => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "What is custom software development?",
      answer: "Custom software development creates software specifically designed for your business needs, unlike generic, off-the-shelf solutions.It offers customized features and flexibility, ensuring the software aligns perfectly with your processes and can grow with your business over time.",
    },
    {
      id: "panel2",
      question: "Why should one go for software development?",
      answer: "Custom software development is ideal for businesses that want a solution designed specifically for their operations.It allows for greater flexibility, as the software can be customized to fit unique processes and grow with the business.Unlike off- the - shelf solutions, custom software can integrate seamlessly with existing systems and offer enhanced security tailored to your needs.While it may require a larger initial investment, the long - term benefits include cost savings on licensing and upgrades, as well as the ability to stay ahead of the competition with a personalized, efficient tool."
    },
    {
      id: "panel3",
      question: "What technologies do you use for custom software development?",
      answer: "We use a range of modern technologies such as React, Node.js, .NET, PHP, and more, depending on your project’s specific requirements."
    },
    {
      id: "panel4",
      question: "How long does it take to develop custom software?",
      answer: "The timeline for custom software development varies depending on the complexity of the project, the features required, and your specific needs. Typically, it can take several weeks to several months."
    },
    {
      id: "panel5",
      question: "How much does custom software development cost?",
      answer: "The cost depends on the scope of the project, including the complexity, number of features, technology stack, and development time. We provide a detailed estimate after understanding your requirements."
    },
  ]
  return (
    <>
      <Helmet>
        <title>Custom Software Development | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner service-details-banner bg-light-purple"
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <div className="left">
              <Breadcrumbs
                separator={
                  <img
                    loading="lazy"
                    src={images.CaretRight}
                    alt="Caret Right"
                  />
                }
                data-aos="fade-up"
              >
                <Link to={`/${link}`} title={link}>
                  {link.charAt(0).toUpperCase() + link.slice(1)}
                </Link>
                <Typography>Custom Software Development</Typography>
              </Breadcrumbs>
              <Typography
                component="h2"
                variant="h2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Custom Software Development
              </Typography>
              <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                <Button
                  variant="contained"
                  color="primary"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  title="Get A Quote"
                >
                  <span className="text">Get A Quote</span>
                </Button>
              </Link>
            </div>
            <div className="right" data-aos="fade-up">
              <img
                loading="lazy"
                src={images.CustomSoftwareDevelopmentBannerImage}
                alt="Custom Software Development"
                width="483"
                height="473"
              />
            </div>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        <section className="service-details-content">
          <Typography component="p" variant="body1" data-aos="fade-up">
            Before designing your software, our business development team will
            collaborate with you to understand your exact requirements. Based on
            your present problems, our expert team will offer you personalized
            solutions to help your business grow. We offer comprehensive custom
            software development services that meet your business's specific
            requirements. Our advanced systems and solutions are designed to
            improve operational efficiency and enhance growth potential, thereby
            allowing businesses to attain their objectives with minimal
            resources.
          </Typography>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.CustomSoftware}
                  alt="Custom Software"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  What is Custom Software?
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  className="pb-4"
                  data-aos-delay="100"
                >
                  Custom software development refers to the process of creating
                  a tailored software solution designed for a specific business.
                  This software is usually more aligned with the workflows,
                  processes, and goals of the company than off-the-shelf
                  systems. It targets particular problems and requirements that
                  generic software may not adequately address.
                  <br />
                  <br /> Some features of these software are:
                </Typography>
                <Grid
                  container
                  spacing={3}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Scalability
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Integration with existing systems
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Enhanced security
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Cost-effectiveness in the long run
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Flexibility and adaptability
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Improved user experience
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Long-term support and maintenance
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  How We Develop Custom Software
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  className="pb-4"
                  data-aos-delay="100"
                >
                  Firstly, we gather your detailed requirements from your team
                  to better understand the needs. Thereafter, our experts design
                  a custom software solution that suits your unique processes.
                  Then, we make it in stages. After every stage, we test it and
                  ask for your feedback. Afterward, we deploy it smoothly into
                  your existing system before providing ongoing help to keep the
                  system running without any glitch.
                </Typography>
                {/* <Grid
                  container
                  spacing={3}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Gathering detailed requirements
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Developing and testing in stages
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Using your feedback for improvements
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Ensuring high quality and reliability
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Seamlessly integrating with existing systems
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Deploying the final solution
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Providing continuous support and maintenance
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Offering regular updates and enhancements
                      </Typography>
                    </div>
                  </Grid>
                </Grid> */}
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.BuildCustomSoftware}
                  alt="How We Build Custom Software"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.PowerBusinessGrowth}
                  alt="Impact of Custom Software"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Impact of Custom Software
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  className="pb-4"
                  data-aos-delay="100"
                >
                  Custom-built applications enhance efficiency through
                  fit-to-purpose workflows, which reduce manual workloads and
                  increase productivity. In addition, these automated tasks
                  minimize human error, leading to more precise results in daily
                  operations. Specific solutions create an edge over competitors
                  because they address distinct needs more effectively than
                  their generic counterparts, thus resulting in higher customer
                  satisfaction levels and improved market positioning. <br />
                  <br />
                  Here are a few benefits you’ll get with custom software:
                </Typography>
                {/* <Grid
                  container
                  spacing={3}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Scalability
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Integration with existing systems
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Enhanced security
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Cost-effectiveness in the long run
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Flexibility and adaptability
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Improved user experience
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Long-term support and maintenance
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Data-driven decision-making
                      </Typography>
                    </div>
                  </Grid>
                </Grid> */}
              </div>
            </Grid>
          </Grid>
        </section>
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default CustomSoftwareDevelopment;
