import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import images from "../../assets/images";

interface testimonialCardProps {
  feedback: string;
  name: string;
  role: string;
  companyLogo: string;
  companyName: string;
}

const TestimonialCard: React.FC<testimonialCardProps> = ({
  feedback,
  name,
  role,
  companyLogo,
  companyName,
}) => {
  return (
    <>
      <Card className="testimonial-card">
        <CardContent>
          <img className="icon" src={images.Quote} alt="Quote" />
          <Typography variant="subtitle1" component="p">
            {feedback}
          </Typography>
          <div className="bottom">
            <div className="left">
              <Typography variant="subtitle2" component="span">
                {name}
              </Typography>
              <Typography variant="caption" component="small">
                {role}
              </Typography>
            </div>
            <img loading="lazy" src={images[companyLogo]} alt={companyName} />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default TestimonialCard;
