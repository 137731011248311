import React from "react";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

interface breadcrumbsProps {
  link: string;
}

const HiringDedicatedDeveloper: React.FC<breadcrumbsProps> = ({ link }) => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "What are dedicated developers and how can they benefit my project?",
      answer: "Dedicated developers are highly skilled professionals who work exclusively on your project. Hiring dedicated developers from Unique IT Solution provides several benefits:",
      parameter: [
        {
          "Focused Expertise": "Our developers specialize in various technologies, ensuring that you get top-notch expertise tailored to your project needs.",
          "Consistency and Continuity": "With a dedicated developer, you maintain continuity in your project, reducing the learning curve and ensuring consistent quality.",
          "Flexible Engagement": "You can scale your development team up or down based on project requirements, providing you with flexibility and cost- efficiency.",
          "Enhanced Communication": "Our developers work closely with your team, providing real- time updates and aligning closely with your project goals."
        }
      ]
    },
    {
      id: "panel2",
      question: "What is the process for hiring dedicated developers from Unique IT Solution?",
      answer: " The process for hiring dedicated developers from Unique IT Solution is simple and transparent. Start by discussing your project needs with us. We will provide you with a selection of qualified developers. Once you choose your candidates, we manage the onboarding and integration. Throughout the project, we keep you updated with regular communication."
    },
    {
      id: "panel3",
      question: "How do dedicated developers integrate with my team?",
      answer: "Dedicated developers from Unique IT Solution work closely with your existing team, blending seamlessly into your workflow and aligning with your project goals."
    },
    {
      id: "panel4",
      question: "How can I track the progress of my project with dedicated developers?",
      answer: "Unique IT Solution keeps you informed with regular updates through status meetings, detailed progress reports, and access to project management tools. You can monitor the project's progress via clear milestones, daily or weekly updates, and client portals."
    },
    {
      id: "panel5",
      question: "How do I get started with hiring a dedicated developer? ",
      answer: "To get started, contact Unique IT Solution with your project requirements. We will guide you through the process, from selecting developers to onboarding them for your project."
    },
  ]
  return (
    <>
      <Helmet>
        <title>Hiring Dedicated Developer | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner service-details-banner bg-light-purple"
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <div className="left">
              <Breadcrumbs
                separator={
                  <img
                    loading="lazy"
                    src={images.CaretRight}
                    alt="Caret Right"
                  />
                }
                data-aos="fade-up"
              >
                <Link to={`/${link}`} title={link}>
                  {link.charAt(0).toUpperCase() + link.slice(1)}
                </Link>
                <Typography>Hiring a Dedicated Developer</Typography>
              </Breadcrumbs>
              <Typography
                component="h2"
                variant="h2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Hiring a Dedicated Developer
              </Typography>
              <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                <Button
                  variant="contained"
                  color="primary"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  title="Get A Quote"
                >
                  <span className="text">Get A Quote</span>
                </Button>
              </Link>
            </div>
            <div className="right" data-aos="fade-up">
              <img
                loading="lazy"
                src={images.DedicatedDeveloperBannerImage}
                alt="IT consulting"
                width="483"
                height="473"
              />
            </div>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        {/* consider hiring developer start */}
        <section className="consider-hiring-developer mb-9" data-aos="fade-in">
          <Typography
            component="h5"
            variant="h5"
            align="center"
            className="pb-4"
            data-aos="fade-up"
          >
            Are you thinking about hiring a dedicated developer who can improve
            your team’s skills and boost your projects’ success?
          </Typography>
          <Typography
            component="p"
            variant="body1"
            align="center"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Understanding the various pricing models—hourly versus monthly—can
            help you make the right choice.
          </Typography>
          <img
            src={images.OvalLeft}
            className="left"
            alt="Oval"
            data-aos="fade-in"
          />
          <img
            src={images.OvalRight}
            className="right"
            alt="Oval"
            data-aos="fade-in"
          />
        </section>
        {/* consider hiring developer end */}

        {/* benefits of hiring start */}
        <section className="benefits-of-hiring mb-9">
          <Typography
            component="h4"
            variant="h4"
            className="pb-6"
            data-aos="fade-up"
            align="center"
          >
            Importance of Hiring a Truly Dedicated and Skilled Developer
          </Typography>
          <Grid container spacing={{ xl: 6, xs: 5 }}>
            <Grid item lg={4} xs={12} data-aos="fade-up">
              <Card>
                <CardContent>
                  <img
                    loading="lazy"
                    src={images.ExpertiseAndFocus}
                    alt="Expertise and Focus"
                  />
                  <Typography
                    variant="h6"
                    align="center"
                    className="pb-3"
                    component="h6"
                  >
                    Specialization and Concentration
                  </Typography>
                  <Typography variant="body2" align="center" component="p">
                    Our dedicated developers have specialized skills that ensure
                    quality work and faster delivery times.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} xs={12} data-aos="fade-up" data-aos-delay="100">
              <Card>
                <CardContent>
                  <img
                    loading="lazy"
                    src={images.FlexibilityAndScalability}
                    alt="Flexibility and Scalability"
                  />
                  <Typography
                    variant="h6"
                    align="center"
                    className="pb-3"
                    component="h6"
                  >
                    Flexibility and Scalability
                  </Typography>
                  <Typography variant="body2" align="center" component="p">
                    Whether it is short-term or continuous assistance, our
                    developers will provide flexibility to scale resources
                    according to your project requirements. They easily blend
                    with your team or work independently as required.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} xs={12} data-aos="fade-up" data-aos-delay="200">
              <Card>
                <CardContent>
                  <img
                    loading="lazy"
                    src={images.CostEfficiency}
                    alt="Cost Efficiency"
                  />
                  <Typography
                    variant="h6"
                    align="center"
                    className="pb-3"
                    component="h6"
                  >
                    Cost Effectiveness
                  </Typography>
                  <Typography variant="body2" align="center" component="p">
                    Your job’s scope, deadline, and budget should determine
                    whether you choose hourly or monthly billing.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </section>
        {/* benefits of hiring end */}
      </Container>
      {/* pricing methods start */}
      <section className="outer-card pricing-methods mb-9">
        <Container maxWidth="xl">
          <Typography
            variant="h3"
            align="center"
            className="pb-6"
            component="h3"
            data-aos="fade-up"
          >
            Pricing Strategies
          </Typography>
          <Grid container spacing={5}>
            <Grid item lg={6} xs={12} data-aos="fade-up">
              <Card>
                <div className="top">
                  <Typography color="primary" variant="h6" component="h6">
                    Hourly Pricing
                  </Typography>
                  <img loading="lazy" src={images.Clock} alt="Clock" />
                </div>
                <CardContent>
                  <ul className="bullet-points-list">
                    <li>
                      <ul>
                        <li className="title">
                          <img
                            loading="lazy"
                            src={images.OutlinedCheckmarkPrimary}
                            alt="Checkmark"
                          />
                          <Typography variant="subtitle2" component="p">
                            Adaptability
                          </Typography>
                        </li>
                        <li>
                          Hourly pay for developers has the advantage of
                          permitting changes in scopes and workloads. Therefore,
                          it allows modifying resources according to developing
                          needs.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <li className="title">
                          <img
                            loading="lazy"
                            src={images.OutlinedCheckmarkPrimary}
                            alt="Checkmark"
                          />
                          <Typography variant="subtitle2" component="p">
                            Monitoring Expense
                          </Typography>
                        </li>
                        <li>
                          For intermittent or time-limited projects, charging by
                          the hour can be very cost-effective. You need only
                          make payments for time spent on tasks, which results
                          in decreased expenditures during slow periods.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <li className="title">
                          <img
                            loading="lazy"
                            src={images.OutlinedCheckmarkPrimary}
                            alt="Checkmark"
                          />
                          <Typography variant="subtitle2" component="p">
                            Tracking Progress
                          </Typography>
                        </li>
                        <li>
                          You can know what hours were used working on each
                          task/phase as well as their total number thereby
                          making them accountable.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                    <Button
                      variant="contained"
                      endIcon={
                        <img
                          loading="lazy"
                          src={images.RightArrowWhite}
                          alt="Right Arrow"
                        />
                      }
                      title="Hire Now"
                    >
                      <span className="text">Hire Now</span>
                    </Button>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={6} xs={12} data-aos="fade-up" data-aos-delay="100">
              <Card>
                <div className="top">
                  <Typography color="primary" variant="h6" component="h6">
                    Monthly Pricing
                  </Typography>
                  <img loading="lazy" src={images.Calendar} alt="Calendar" />
                </div>
                <CardContent>
                  <ul className="bullet-points-list">
                    <li>
                      <ul>
                        <li className="title">
                          <img
                            loading="lazy"
                            src={images.OutlinedCheckmarkPrimary}
                            alt="Checkmark"
                          />
                          <Typography variant="subtitle2" component="p">
                            Budgetary Predictability
                          </Typography>
                        </li>
                        <li>
                          Monthly fees that do not change from month to month
                          lead to stability in budgetary matters, which is
                          convenient for ongoing projects such as continual
                          development support.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <li className="title">
                          <img
                            loading="lazy"
                            src={images.OutlinedCheckmarkPrimary}
                            alt="Checkmark"
                          />
                          <Typography variant="subtitle2" component="p">
                            Dedication and Commitment
                          </Typography>
                        </li>
                        <li>
                          Developers under this plan are bound by an agreement
                          that makes them available throughout the month at
                          certain hours, ensuring they remain dedicated to their
                          assignments.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <li className="title">
                          <img
                            loading="lazy"
                            src={images.OutlinedCheckmarkPrimary}
                            alt="Checkmark"
                          />
                          <Typography variant="subtitle2" component="p">
                            Long-Term Relationships
                          </Typography>
                        </li>
                        <li>
                          This type of payment helps build stronger
                          relationships between developers and your team
                          members. As a result, it promotes closer cooperation,
                          hence better collaboration among participants.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                    <Button
                      variant="contained"
                      endIcon={
                        <img
                          loading="lazy"
                          src={images.RightArrowWhite}
                          alt="Right Arrow"
                        />
                      }
                      title="Hire Now"
                    >
                      <span className="text">Hire Now</span>
                    </Button>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </section>
      {/* pricing methods end */}
      <Container maxWidth="xl">
        <Grid container spacing={5} className="mb-9 section-details">
          <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
            <div className="content">
              <Typography
                component="h3"
                variant="h3"
                className="pb-4"
                data-aos="fade-up"
              >
                Choosing the Right Model
              </Typography>
              <ul
                className="bullet-points-list"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <li>
                  <ul>
                    <li className="title">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography variant="subtitle2" component="p">
                        Nature of the project
                      </Typography>
                    </li>
                    <li>
                      Whether your project is for a short term or a long time,
                      you should consider its nature. Hourly billing is
                      beneficial for quick experiments, while continuous
                      development/support may be best suited with monthly rates.
                    </li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li className="title">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography variant="subtitle2" component="p">
                        Budget Considerations
                      </Typography>
                    </li>
                    <li>
                      Look at your spending plan and budgeting needs. Hourly
                      charges are flexible but can vary, whereas a fixed monthly
                      fee is stable and allows for easy budget forecasting.
                    </li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li className="title">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography variant="subtitle2" component="p">
                        Communication and Collaboration
                      </Typography>
                    </li>
                    <li>
                      Decide on the level of integration required from
                      developers during your project. Monthly pricing leads to
                      more team alignment and collaboration.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
            <div className="image-wrapper" data-aos="fade-up">
              <img
                loading="lazy"
                src={images.ChooseModel}
                alt="Choosing the Right Model"
              />
            </div>
          </Grid>
        </Grid>
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default HiringDedicatedDeveloper;
