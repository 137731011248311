import { CircularProgress } from "@mui/material";
import React from "react";

const PageLoader: React.FC = () => {
  return (
    <>
      <div className="loader">
        <CircularProgress size={56} thickness={4} />
      </div>
    </>
  );
};

export default PageLoader;
