import React, { useEffect, useState } from "react";
import { NavLink, Link as RouterLink } from "react-router-dom";
import images from "../../assets/images";
import Button from "@mui/material/Button";
import Aos from "aos";
import { Collapse, useMediaQuery, IconButton } from "@mui/material";
import theme from "../App.theme";

const Header: React.FC = () => {
  const [showServicesSubmenu, setShowServicesSubmenu] = useState(
    useMediaQuery(theme.breakpoints.up("xl")) ? true : false
  );
  const [showIndustriesSubmenu, setShowIndustriesSubmenu] = useState(
    useMediaQuery(theme.breakpoints.up("xl")) ? true : false
  );

  const [pageScrolled, setPageScrolled] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("xl"));

  const handleServiciesSubmenuToggle = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (isMobile) {
      event.preventDefault();
      setShowServicesSubmenu((prev) => !prev);
      setShowIndustriesSubmenu(false);
    }
  };

  const handleIndustriesSubmenuToggle = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (isMobile) {
      event.preventDefault();
      setShowIndustriesSubmenu((prev) => !prev);
      setShowServicesSubmenu(false);
    }
  };

  const sidebarToggle = () => {
    document.body.classList.toggle("show-sidebar");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setPageScrolled(true);
      } else {
        setPageScrolled(false);
      }
    };

    const handleResize = () => {
      setShowServicesSubmenu(isMobile ? false : true);
      setShowIndustriesSubmenu(isMobile ? false : true);
    };

    Aos.init({
      duration: 700,
      once: true,
    });

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    Aos.refresh();
  }, [pageScrolled]);

  return (
    <>
      <header
        className={pageScrolled ? "page-scrolled" : ""}
        data-aos="fade-in"
      >
        <IconButton size="small" onClick={sidebarToggle} title="Menu">
          <img loading="lazy" src={images.Hamburger} alt="Hamburger" />
          <img
            loading="lazy"
            src={images.HamburgerWhite}
            className="active"
            alt="Hamburger"
          />
        </IconButton>
        <RouterLink
          className="logo"
          to={`${process.env.PUBLIC_URL}/`}
          title="Unique IT Solution"
        >
          <img loading="lazy" src={images.Logo} alt="Unique IT Solution" />
          <img
            loading="lazy"
            className="mobile-logo"
            src={images.MobileLogo}
            alt="Unique IT Solution"
          />
        </RouterLink>
        <ul>
          <li>
            <NavLink to={`${process.env.PUBLIC_URL}/`} title="Home">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to={`${process.env.PUBLIC_URL}/about-us`} title="About Us">
              About Us
            </NavLink>
          </li>
          <li className="has-submenu">
            <NavLink
              to={`${process.env.PUBLIC_URL}/services`}
              title="Services"
              onClick={handleServiciesSubmenuToggle}
              className={`${showServicesSubmenu ? "menu-expanded" : ""}`}
            >
              Services
              <img loading="lazy" src={images.ArrowDown} alt="Down Arrow" />
            </NavLink>
            <Collapse in={showServicesSubmenu}>
              <ul className="submenu">
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/services/custom-software-development`}
                    title="Custom Software Development"
                    state={{ from: "home" }}
                  >
                    Custom Software Development
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/services/work-flow-automation`}
                    title="Work Flow Automation"
                    state={{ from: "home" }}
                  >
                    Work Flow Automation
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/services/crm-and-erp-development`}
                    title="CRM & ERP Development"
                    state={{ from: "home" }}
                  >
                    CRM & ERP Development
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/services/web-development`}
                    title="Web Development"
                    state={{ from: "home" }}
                  >
                    Web Development
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/services/mobile-application-development`}
                    title="Mobile Application Development"
                    state={{ from: "home" }}
                  >
                    Mobile Application Development
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/services/ui-ux-design`}
                    title="UI/UX Design"
                    state={{ from: "home" }}
                  >
                    UI/UX Design
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/services/hire-dedicated-developers`}
                    title="Hire Dedicated Developers"
                    state={{ from: "home" }}
                  >
                    Hire Dedicated Developers
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/services/it-consulting`}
                    title="IT Consulting"
                    state={{ from: "home" }}
                  >
                    IT Consulting
                  </NavLink>
                </li>
              </ul>
            </Collapse>
          </li>
          <li>
            <NavLink
              to={`${process.env.PUBLIC_URL}/technologies`}
              title="Technologies"
            >
              Technologies
            </NavLink>
          </li>
          <li className="has-submenu">
            <NavLink
              to={`${process.env.PUBLIC_URL}/industries`}
              title="Industries"
              onClick={handleIndustriesSubmenuToggle}
              className={`${showIndustriesSubmenu ? "menu-expanded" : ""}`}
            >
              Industries
              <img loading="lazy" src={images.ArrowDown} alt="Down Arrow" />
            </NavLink>
            <Collapse in={showIndustriesSubmenu}>
              <ul className="submenu">
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/industries/hospitality-industry`}
                    title="Hospitality Industry"
                    state={{ from: "home" }}
                  >
                    Hospitality Industry
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/industries/retail-and-ecommerce-solution`}
                    title="Retail & Ecommerce Solution"
                    state={{ from: "home" }}
                  >
                    Retail & Ecommerce Solution
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/industries/news-and-entertainment`}
                    title="News & Entertainment"
                    state={{ from: "home" }}
                  >
                    News & Entertainment
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/industries/production-and-service-assurance`}
                    title="Production & Service Assurance"
                    state={{ from: "home" }}
                  >
                    Production & Service Assurance
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/industries/telecommunication-media`}
                    title="Telecommunication Media"
                    state={{ from: "home" }}
                  >
                    Telecommunication Media
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/industries/logistic-and-distribution`}
                    title="Logistic & Distribution"
                    state={{ from: "home" }}
                  >
                    Logistic & Distribution
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/industries/food-and-beverages`}
                    title="Food & Beverages"
                    state={{ from: "home" }}
                  >
                    Food & Beverages
                  </NavLink> 
                </li> */}
              </ul>
            </Collapse>
          </li>
          {/* <li>
            <NavLink
              to={`${process.env.PUBLIC_URL}/portfolio`}
              title="Portfolio"
            >
              Portfolio
            </NavLink>
          </li> */}
        </ul>
        <RouterLink to={`${process.env.PUBLIC_URL}/contact-us`}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            title="Contact Us"
          >
            <span className="text">Contact Us</span>
          </Button>
        </RouterLink>
        <div className="sidebar-overlay" onClick={sidebarToggle}></div>
      </header>
    </>
  );
};

export default Header;
