import React from "react";
import { useLocation, useParams } from "react-router-dom";
import FoodBeverages from "./FoodBeverages";
import HospitalityIndustry from "./HospitalityIndustry";
import LogisticDistribution from "./LogisticDistribution";
import NewsAndEntertainment from "./NewsAndEntertainment";
import ProductionAndServiceAssurance from "./ProductionAndServiceAssurance";
import RetailAndEcommerceSolution from "./RetailAndEcommerceSolution";
import TelecommunicationMedia from "./TelecommunicationMedia";

interface IndustryComponentProps {
  link: string;
}

type IndustryComponentType = React.ComponentType<IndustryComponentProps>;

const industries: { [key: string]: IndustryComponentType } = {
  "hospitality-industry": HospitalityIndustry,
  "news-and-entertainment": NewsAndEntertainment,
  "retail-and-ecommerce-solution": RetailAndEcommerceSolution,
  "production-and-service-assurance": ProductionAndServiceAssurance,
  "telecommunication-media": TelecommunicationMedia,
  "logistic-and-distribution": LogisticDistribution,
  "food-and-beverages": FoodBeverages,
};

const IndustryDetailsMain: React.FC = () => {
  const { industryName } = useParams<{ industryName: string }>();
  const ServiceComponent = industryName ? industries[industryName] : null;
  const location = useLocation();
  const { from } = location.state || { from: "default" };

  return ServiceComponent ? (
    <ServiceComponent link={from} />
  ) : (
    <div>Service not found</div>
  );
};

export default IndustryDetailsMain;
