import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@mui/material";
import images from "../../assets/images";
import { Link } from "react-router-dom";

interface serviceCardProps {
  icon: string;
  title: string;
  description: string;
  link: string;
  iconBg: string;
  from: string;
}

const ServiceCard: React.FC<serviceCardProps> = ({
  icon,
  title,
  description,
  link,
  iconBg,
  from,
}) => {
  return (
    <>
      <Card className="service-card">
        <CardContent>
          <div className={`icon ${iconBg}`}>
            <img src={icon} alt={title} />
          </div>
          <Typography variant="h6" component="h6">
            {title}
          </Typography>
          <Typography variant="body2" component="p">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Link to={`${process.env.PUBLIC_URL}${link}`} state={{ from: from }}>
            <Button
              variant="text"
              endIcon={
                <img
                  loading="lazy"
                  src={images.RightArrowPrimary}
                  alt="Right Arrow"
                />
              }
              title="Discover More"
            >
              Discover More
            </Button>
          </Link>
        </CardActions>
      </Card>
    </>
  );
};

export default ServiceCard;
