const images: { [key: string]: string } = {
  Logo: require("./logo.webp"),
  AboutItSolution: require("./about-it-solution.svg").default,
  CRMERPDevelopmentIcon: require("./ic-crm-erp-development.svg").default,
  CustomSoftwareDevelopmentIcon: require("./ic-custom-software-development.svg")
    .default,
  HireDeveloperIcon: require("./ic-hire-developer.svg").default,
  ITConsultingIcon: require("./ic-it-consulting.svg").default,
  MobileDevelopmentIcon: require("./ic-mobile-development.svg").default,
  UIUXDesignIcon: require("./ic-ui-ux-design.svg").default,
  WebDevelopmentIcon: require("./ic-web-development.svg").default,
  WorkFlowAutomationIcon: require("./ic-work-flow-automation.svg").default,
  RightArrowPrimary: require("./ic-right-arrow-primary.svg").default,
  NavArrowLeft: require("./ic-nav-arrow-left.svg").default,
  NavArrowLeftWhite: require("./ic-nav-arrow-left-white.svg").default,
  NavArrowRight: require("./ic-nav-arrow-right.svg").default,
  NavArrowRightWhite: require("./ic-nav-arrow-right-white.svg").default,
  RightArrowWhite: require("./ic-right-arrow-white.svg").default,
  HospitalityIndustry: require("./hospitality-industry.webp"),
  LogisticDistribution: require("./logistic-distribution.webp"),
  FoodBeverages: require("./food-beverages.webp"),
  ManufacturingAndProcessingFacilities: require("./manufacturing-and-processing-facilities.webp"),
  NewsAndEntertainment: require("./news-and-entertainment.webp"),
  RetailAndEcommerceSolution: require("./retail-and-ecommerce-solution.webp"),
  TelecommunicationMedia: require("./telecommunication-media.webp"),
  HowWeWorkIllustration1: require("./how-we-work-illustration-1.svg").default,
  HowWeWorkIllustration2: require("./how-we-work-illustration-2.svg").default,
  PaperWork: require("./ic-paper-work.svg").default,
  BusinessCentricApproach: require("./ic-business-centric-approach.svg")
    .default,
  CustomizedSolution: require("./ic-customized-solution.svg").default,
  AgileBasedDecision: require("./ic-agile-based-decision.svg").default,
  InnovativeApproach: require("./ic-innovative-approach.svg").default,
  HighCustomerSatisfaction: require("./ic-high-customer-satisfaction.svg")
    .default,
  Rotilaa: require("./rotilaa.webp"),
  Dicasa: require("./dicasa.webp"),
  IskonGathiya: require("./iskon-gathiya.webp"),
  AppleFitting: require("./apple-fitting.webp"),
  SloveTiles: require("./slove-tiles.webp"),
  WingsCera: require("./wings-cera.webp"),
  KetoneMica: require("./ketone-mica.webp"),
  AsianFresh: require("./asian-fresh.webp"),
  GrowmoreLaminate: require("./growmore-laminate.webp"),
  Suntouch: require("./suntouch.webp"),
  KingdomInternational: require("./kingdom-international.webp"),
  KloudDial: require("./kloud-dial.webp"),
  Vaidehi: require("./vaidehi.webp"),
  AhmedabadMedia: require("./ahmedabad-media.webp"),
  TopTalents: require("./top-talents.webp"),
  OutlinedCheckmarkPrimary: require("./ic-outlined-checkmark-primary.svg")
    .default,
  FAQ: require("./FAQ.svg").default,
  ArrowUpPrimary: require("./ic-arrow-up-primary.svg").default,
  ArrowDown: require("./ic-arrow-down.svg").default,
  EmailWhite: require("./ic-email-white.svg").default,
  CallWhite: require("./ic-call-white.svg").default,
  CallPrimary: require("./ic-call-primary.svg").default,
  EmailPrimary: require("./ic-email-primary.svg").default,
  LocationPrimary: require("./ic-location-primary.svg").default,
  Facebook: require("./ic-facebook.svg").default,
  Instagram: require("./ic-instagram.svg").default,
  Linkedin: require("./ic-linkedin.svg").default,
  OvalLeft: require("./oval-left.svg").default,
  OvalRight: require("./oval-right.svg").default,
  Hamburger: require("./ic-hamburger.svg").default,
  HamburgerWhite: require("./ic-hamburger-white.svg").default,
  MobileLogo: require("./mobile-logo.webp"),
  LinkedinWhite: require("./ic-linkedin-white.svg").default,
  InstagramWhite: require("./ic-instagram-white.svg").default,
  FacebookWhite: require("./ic-facebook-white.svg").default,
  AboutUsBanner: require("./about-us-banner.webp"),
  ServicesBanner: require("./services-banner.webp"),
  EmpoweringDigitalTransformation:
    require("./empowering-digital-transformation.svg").default,
  PowerBusinessGrowth: require("./power-business-growth.svg").default,
  EnhancingOnlineVisibility: require("./enhancing-online-visibility.svg")
    .default,
  CustomerServiceSupport: require("./customer-service-support.svg").default,
  AutomatingRepetitiveTasks: require("./automating-repetitive-tasks.svg")
    .default,
  DataEntry: require("./data-entry.svg").default,
  ErrorDetection: require("./error-detection.svg").default,
  SupplyChain: require("./supply-chain.svg").default,
  CustomerInteractionSystem: require("./customer-interaction-system.svg")
    .default,
  SupportTicketManagement: require("./support-ticket-management.svg").default,
  CustomerNotification: require("./customer-notification.svg").default,
  OrderManagement: require("./order-management.svg").default,
  InventoryManagement: require("./inventory-management.svg").default,
  CustomSoftware: require("./custom-software.svg").default,
  BuildCustomSoftware: require("./build-custom-software.svg").default,
  RequirementGathering: require("./requirement-gathering.svg").default,
  Prototyping: require("./prototyping.svg").default,
  DevelopmentAndTesting: require("./development-and-testing.svg").default,
  QualityAssurance: require("./quality-assurance.svg").default,
  DeploymentSupport: require("./deployment-support.svg").default,
  UserCenteredDesign: require("./user-centered-design.svg").default,
  VisualDesign: require("./visual-design.svg").default,
  UsabilityTesting: require("./usability-testing.svg").default,
  ChooseModel: require("./choosing-model.svg").default,
  WorkFlowAutomationBannerImage: require("./work-flow-automation.svg").default,
  CaretRight: require("./ic-caret-right.svg").default,
  CRMAndERPDevelopmentBannerImage: require("./crm-and-erp-development.svg")
    .default,
  CustomSoftwareDevelopmentBannerImage:
    require("./custom-software-development.svg").default,
  WebDevelopmentBannerImage: require("./web-development.svg").default,
  MobileApplicationDevelopmentBannerImage:
    require("./mobile-application-development.svg").default,
  UIUXDesignBannerImage: require("./ui-ux-design.svg").default,
  ITConsultingBannerImage: require("./it-consulting.svg").default,
  DedicatedDeveloperBannerImage: require("./dedicated-developer.svg").default,
  ToThePointSolutions: require("./ic-to-the-point-solutions.svg").default,
  CostEffectiveness: require("./ic-cost-effectiveness.svg").default,
  BestCollaboration: require("./ic-best-collaboration.svg").default,
  BusinessAnalysis: require("./ic-business-analysis.svg").default,
  AnalyticalInsights: require("./ic-analytical-insights.svg").default,
  ImplementationExcellence: require("./ic-implementation-excellence.svg")
    .default,
  ContinuousImprovement: require("./ic-continuous-improvement.svg").default,
  ConsultingSolution: require("./ic-consulting-solution.svg").default,
  ExpertiseAndFocus: require("./ic-expertise-and-focus.svg").default,
  FlexibilityAndScalability: require("./ic-flexibility-and-scalability.svg")
    .default,
  CostEfficiency: require("./ic-cost-efficiency.svg").default,
  Clock: require("./ic-clock.svg").default,
  Calendar: require("./ic-calendar.svg").default,
  TechnologiesBanner: require("./technologies-banner.webp"),
  FrontendTechnologies: require("./frontend-technologies.svg").default,
  BackendTechnologies: require("./backend-technologies.svg").default,
  DatabaseTechnologies: require("./database-technologies.svg").default,
  MobileApplicationDevelopmentTechnologies:
    require("./mobile-application-development-technologies.svg").default,
  CloudTechnologies: require("./cloud-technologies.svg").default,
  UIUXTechnologies: require("./ui-ux-technologies.svg").default,
  Quote: require("./ic-quote.svg").default,
  IndustriesBanner: require("./industries-banner.webp"),
  TestimonialRotilaa: require("./testimonial-rotilaa.webp"),
  TestimonialAppleFitting: require("./testimonial-apple-fitting.webp"),
  TestimonialDicasaCeramica: require("./testimonial-dicasa-ceramica.webp"),
  TestimonialWingsCera: require("./testimonial-wings-cera.webp"),
  TestimonialSloveTiles: require("./testimonial-slove-tiles.webp"),
  TestimonialIskonGathiya: require("./testimonial-iskon-gathiya.webp"),
  VisitOurPortfolio: require("./visit-our-portfolio.webp"),
  GridBG: require("./grid-bg.svg").default,
  HospitalityIndustryBanner: require("./hospitality-industry-banner.webp"),
  CaretRightWhite: require("./ic-caret-right-white.svg").default,
  Question: require("./ic-question.svg").default,
  Bulb: require("./ic-bulb.svg").default,
  DashBorderLeft: require("./dash-border-left.svg").default,
  DashBorderRight: require("./dash-border-right.svg").default,
  LogisticDistributionBanner: require("./logistic-distribution-banner.webp"),
  FoodBeveragesBanner: require("./food-beverages-banner.webp"),
  ManufacturingAndProcessingFacilitiesBanner: require("./manufacturing-and-processing-facilities-banner.webp"),
  NewsAndEntertainmentBanner: require("./news-and-entertainment-banner.webp"),
  RetailAndEcommerceSolutionBanner: require("./retail-and-ecommerce-solution-banner.webp"),
  TelecommunicationMediaBanner: require("./telecommunication-media-banner.webp"),
  ContactUsBanner: require("./contact-us-banner.webp"),
  User: require("./ic-user.svg").default,
  Call: require("./ic-call.svg").default,
  Email: require("./ic-email.svg").default,
  ContactUs: require("./contact-us.svg").default,
  PortfolioBanner: require("./portfolio-banner.webp"),
  PortfolioCardImg: require("./portfolio-card-img.webp"),
  PlayStorePrimary: require("./ic-play-store-primary.svg").default,
  AppStorePrimary: require("./ic-app-store-primary.svg").default,
  PlayStoreWhite: require("./ic-play-store-white.svg").default,
  AppStoreWhite: require("./ic-app-store-white.svg").default,
  X: require("./ic-x.svg").default,
  XWhite: require("./ic-x-white.svg").default,
  ArrowUp: require("./ic-arrow-up.svg").default,
  Upload: require("./ic-upload.svg").default,
  File: require("./ic-file.svg").default,
  Delete: require("./ic-delete.svg").default,
  DeleteWhite: require("./ic-delete-white.svg").default,
};

export default images;
