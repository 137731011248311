import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

interface breadcrumbsProps {
  link: string;
}

const CRMAndERPDevelopment: React.FC<breadcrumbsProps> = ({ link }) => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "What is CRM and ERP development?",
      answer: "CRM and ERP development involve creating software solutions to manage and streamline business processes. CRM systems focus on improving interactions with customers, while ERP systems integrate and manage core business functions like finance, HR, and inventory.",
    },
    {
      id: "panel2",
      question: " What should I consider when choosing a CRM or ERP system?",
      answer: "Elevate your business with Unique IT Solution's CRM and ERP systems. Improve customer management and satisfaction with our CRM solutions, while our ERP systems will streamline processes, cut costs, and unify your business data."
    },
    {
      id: "panel3",
      question: "What industries can benefit from CRM and ERP systems?",
      answer: " ",
      parameter: [
        {
          BusinessNeeds: "Ensure the CRM or ERP system aligns with Unique IT Solution’s specific goals, such as enhancing customer relationships or optimizing inventory management.",
          Scalability: "Choose a solution that scales with Unique IT Solution’s growing data and user base.",
          Integration: "Select a system that integrates seamlessly with Unique IT Solution’s existing tools, including email and financial software.",
          Cost: "Evaluate the total cost, including upfront, ongoing, and maintenance expenses, ensuring it provides value for Unique IT Solution.",
          Security: "Ensure the system delivers robust data protection and meets compliance requirements critical to Unique IT Solution."
        }
      ]
    },
    {
      id: "panel4",
      question: "How secure are the CRM and ERP systems developed by Unique IT Solution?",
      answer: "Our CRM and ERP systems are designed with robust security measures, including data encryption, secure access controls, and regular security audits. We prioritize the protection of your sensitive data and ensure compliance with industry standards and regulations."
    },
    {
      id: "panel5",
      question: "What is the cost range for developing a custom CRM or ERP system with Unique IT Solution?",
      answer: "The cost of developing a custom CRM or ERP system varies based on factors such as the complexity of features, the size of the system, and the level of customization required. Unique IT Solution provides detailed cost estimates after assessing your specific needs and project scope."
    },
  ]
  return (
    <>
      <Helmet>
        <title>CRM & ERP Development | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner service-details-banner bg-light-blue"
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <div className="left">
              <Breadcrumbs
                separator={
                  <img
                    loading="lazy"
                    src={images.CaretRight}
                    alt="Caret Right"
                  />
                }
                data-aos="fade-up"
              >
                <Link to={`/${link}`} title={link}>
                  {link.charAt(0).toUpperCase() + link.slice(1)}
                </Link>
                <Typography>CRM & ERP Development</Typography>
              </Breadcrumbs>
              <Typography
                component="h2"
                variant="h2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                CRM & ERP Development
              </Typography>
              <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                <Button
                  variant="contained"
                  color="primary"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  title="Get A Quote"
                >
                  <span className="text">Get A Quote</span>
                </Button>
              </Link>
            </div>
            <div className="right" data-aos="fade-up">
              <img
                loading="lazy"
                src={images.CRMAndERPDevelopmentBannerImage}
                alt="CRM & ERP Development"
                width="483"
                height="473"
              />
            </div>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        <section className="service-details-content">
          <Typography component="p" variant="body1" data-aos="fade-up">
            We deal exclusively with customized ERP & CRM development whose
            purpose is to enhance business growth and efficiency, respectively.
            Before designing any ERP, we first understand your business problem
            and goals. To overcome these problems, we carefully design the ERP
            and CRM software that takes you a step forward towards fulfilling
            your goals and reduces your resource costs.
          </Typography>
          <Typography
            component="h3"
            align="center"
            variant="h3"
            className="pb-7"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Our Expertise Includes
          </Typography>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.CustomerInteractionSystem}
                  alt="Customer Interaction System"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Customer Interaction System
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  className="pb-4"
                >
                  Customer interaction can be automated by deploying automated
                  customer interaction solutions that streamline communication
                  processes between customers and the company. With our tailored
                  systems, you can automate responses to inquiries and
                  efficiently handle support tickets. All this can be done by
                  providing constant updates, such as regular notifications
                  about changes or additions of products/services. We help you
                  send these messages via email, thereby promoting information
                  sharing on these platforms. Thus, these solutions improve
                  operational efficiency, leading to better customer service
                  experiences for all clients and resulting in higher customer
                  satisfaction levels within companies.
                </Typography>
                {/* <Grid
                  container
                  spacing={3}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Automated Responses
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Enhanced Operational Efficiency
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Elevated Customer Satisfaction
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Seamless Service Experience
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Smoother business operations
                      </Typography>
                    </div>
                  </Grid>
                </Grid> */}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Support Ticket Management
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  className="pb-4"
                  data-aos-delay="100"
                >
                  In support ticket management systems, we provide automated
                  ticket creation by various channels, intelligent ticket
                  assignment, and tracking through statuses like: “New,” “In
                  Progress,” “Awaiting Customer Response,” “Resolved,” and
                  “Closed.” In addition, real-time resolution tracking,
                  escalation of high-priority issues and prioritization,
                  integrated knowledge base access, and detailed performance
                  analytics through regular reports are provided.
                </Typography>
                {/* <Grid
                  container
                  spacing={3}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Automated Ticket Generation
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Intelligent Ticket Assignment
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Service Ticket Tracking
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Real-Time Resolution Tracking
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Prioritization and Escalation
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Performance Analytics through custom Reports
                      </Typography>
                    </div>
                  </Grid>
                </Grid> */}
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.SupportTicketManagement}
                  alt="Support Ticket Management"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.CustomerNotification}
                  alt="Regular Customer Notifications"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Regular Customer Notifications
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  className="pb-4"
                  data-aos-delay="100"
                >
                  We implement systems to automate customer notifications within
                  ERP and CRM solutions, ensuring timely updates on service
                  acceptance, service status, and regular changes. Moreover,
                  personalized messaging based on customer preferences or
                  interaction history guarantees relevancy. Our multi-channel
                  delivery (email, SMSs, mobile apps, social media platforms)
                  sends out notifications. We also collect customer insights to
                  improve service quality through automated feedback surveys
                  once a customer takes any service.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Order Management
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  className="pb-4"
                  data-aos-delay="100"
                >
                  Our order management system ensures the entire order lifecycle
                  is streamlined from ordering to delivery to guarantee
                  efficiency and accurate processing. This helps in automated
                  order validation procedures with instant inventory updates and
                  shipping provider integration for timely deliveries.
                </Typography>
                {/* <Grid
                  container
                  spacing={3}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Real-Time Order Tracking
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Inventory Integration
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Automated Order Processing
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Shipping Integration
                      </Typography>
                    </div>
                  </Grid>
                </Grid> */}
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.OrderManagement}
                  alt="Inventory Management"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.InventoryManagement}
                  alt="Inventory Management"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Inventory Management
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  className="pb-4"
                  data-aos-delay="100"
                >
                  Our inventory management solutions provide real-time tracking
                  and management of inventory levels helping businesses maintain
                  optimal stock levels and reduce holding costs. Features
                  include automated replenishment, inventory forecasting, as
                  well as detailed reporting.
                </Typography>
                {/* <Grid
                  container
                  spacing={3}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Accurate Inventory Tracking
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Inventory Forecasting
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="bullet-point">
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="checkmark"
                      />
                      <Typography component="p" variant="body2">
                        Detailed Reporting
                      </Typography>
                    </div>
                  </Grid>
                </Grid> */}
              </div>
            </Grid>
          </Grid>
        </section>
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default CRMAndERPDevelopment;
