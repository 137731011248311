import React from "react";
import { useLocation, useParams } from "react-router-dom";
import CRMAndERPDevelopment from "./CRMAndERPDevelopment";
import CustomSoftwareDevelopment from "./CustomSoftwareDevelopment";
import HiringDedicatedDeveloper from "./HiringDedicatedDeveloper";
import ITConsulting from "./ITConsulting";
import MobileApplicationDevelopment from "./MobileApplicationDevelopment";
import UIUXDesign from "./UIUXDesign";
import WebDevelopment from "./WebDevelopment";
import WorkFlowAutomation from "./WorkFlowAutomation";

interface ServiceComponentProps {
  link: string;
}

type ServiceComponentType = React.ComponentType<ServiceComponentProps>;

const services: { [key: string]: ServiceComponentType } = {
  "work-flow-automation": WorkFlowAutomation,
  "crm-and-erp-development": CRMAndERPDevelopment,
  "custom-software-development": CustomSoftwareDevelopment,
  "web-development": WebDevelopment,
  "mobile-application-development": MobileApplicationDevelopment,
  "ui-ux-design": UIUXDesign,
  "it-consulting": ITConsulting,
  "hire-dedicated-developers": HiringDedicatedDeveloper,
};

const ServiceDetailsMain: React.FC = () => {
  const { serviceName } = useParams<{ serviceName: string }>();
  const ServiceComponent = serviceName ? services[serviceName] : null;
  const location = useLocation();
  const { from } = location.state || { from: "default" };

  return ServiceComponent ? (
    <ServiceComponent link={from} />
  ) : (
    <div>Service not found</div>
  );
};

export default ServiceDetailsMain;
