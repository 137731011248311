import React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./components/ScrollToTop";
import Layout from "./layout/Layout";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import Home from "./pages/Home/Home";
import Industries from "./pages/Industries/Industries";
import IndustryDetailsMain from "./pages/IndustryDetails/IndustryDetailsMain";
// import Portfolio from "./pages/Portfolio/Portfolio";
import ServiceDetailsMain from "./pages/ServiceDetails/ServiceDetailsMain";
import Services from "./pages/Services/Services";
import Technologies from "./pages/Technologies/Technologies";

const AppRouter: React.FC = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Layout>

          <Routes>
            <Route path={`${process.env.PUBLIC_URL}/`} element={<Home />}></Route>
            <Route
              path={`${process.env.PUBLIC_URL}/home`}
              element={<Home />}
            ></Route>
            <Route
              path={`${process.env.PUBLIC_URL}/about-us`}
              element={<AboutUs />}
            ></Route>
            <Route
              path={`${process.env.PUBLIC_URL}/services`}
              element={<Services />}
            ></Route>
            <Route
              path={`${process.env.PUBLIC_URL}/services/:serviceName`}
              element={<ServiceDetailsMain />}
            ></Route>
            <Route
              path={`${process.env.PUBLIC_URL}/technologies`}
              element={<Technologies />}
            ></Route>
            <Route
              path={`${process.env.PUBLIC_URL}/industries`}
              element={<Industries />}
            ></Route>
            <Route
              path={`${process.env.PUBLIC_URL}/industries/:industryName`}
              element={<IndustryDetailsMain />}
            ></Route>
            <Route
              path={`${process.env.PUBLIC_URL}/contact-us`}
              element={<ContactUs />}
            ></Route>
            {/* <Route
            path={`${process.env.PUBLIC_URL}/portfolio`}
            element={<Portfolio />}
          ></Route> */}
          </Routes>

        </Layout>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default AppRouter;
