import React from "react";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

interface breadcrumbsProps {
  link: string;
}

const ITConsulting: React.FC<breadcrumbsProps> = ({ link }) => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "What is IT Consulting?",
      answer: "IT Consulting involves providing expert advice and solutions to help businesses improve their IT infrastructure, systems, and processes. Unique IT Solution specializes in delivering tailored consulting services that align with your specific business needs.",
    },
    {
      id: "panel2",
      question: "What types of IT consulting services does Unique IT Solution provide?",
      answer: "We offer a range of IT consulting services, including IT strategic planning, architecture planning, technology assessments, and customer experience consulting. At Unique IT Solution, we specialize in crafting bespoke solutions that align with your business objectives, ensuring that every aspect of your IT infrastructure is optimized for both performance and security."
    },
    {
      id: "panel3",
      question: "How can IT consulting benefit my software development projects?",
      answer: "IT consulting with Unique IT Solution boosts your software development by improving efficiency, reducing development time, and applying best practices. We offer expert insights, optimize architecture, and streamline processes to deliver high-quality software."
    },
    {
      id: "panel4",
      question: "Why should I choose Unique IT Solution for IT consulting in software development?",
      answer: "Unique IT Solution offers specialized IT consulting services tailored to software development. Our experienced consultants understand the intricacies of the software development lifecycle and provide customized solutions to address your specific needs. We focus on delivering innovative strategies and leveraging advanced technologies to enhance your development projects."
    },
    {
      id: "panel5",
      question: "How can I get started with IT Consulting from Unique IT Solution?",
      answer: "To initiate IT consulting services, get in touch with Unique IT Solution to arrange an initial meeting. We will examine your needs, assess your IT infrastructure, and formulate a bespoke consulting plan to align with your business objectives."
    },
  ]
  return (
    <>
      <Helmet>
        <title>IT Consulting | Unique IT Solutions</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner service-details-banner bg-light-purple"
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <div className="left">
              <Breadcrumbs
                separator={
                  <img
                    loading="lazy"
                    src={images.CaretRight}
                    alt="Caret Right"
                  />
                }
                data-aos="fade-up"
              >
                <Link to={`/${link}`} title={link}>
                  {link.charAt(0).toUpperCase() + link.slice(1)}
                </Link>
                <Typography>IT consulting</Typography>
              </Breadcrumbs>
              <Typography
                component="h2"
                variant="h2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                IT consulting
              </Typography>
              <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                <Button
                  variant="contained"
                  color="primary"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  title="Get A Quote"
                >
                  <span className="text">Get A Quote</span>
                </Button>
              </Link>
            </div>
            <div className="right" data-aos="fade-up">
              <img
                loading="lazy"
                src={images.ITConsultingBannerImage}
                alt="IT consulting"
                width="483"
                height="473"
              />
            </div>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        <section className="service-details-content mb-9">
          <Typography component="p" variant="body1" data-aos="fade-up">
            unique it solution prioritizes comprehensive business analysis as
            part of our integrated IT consultancy approach to give us a complete
            understanding of your organization. This empowers us to develop
            customised solutions that effectively respond to your company's
            specific challenges and opportunities, enhancing its efficiency and
            strategic alignment.
          </Typography>
          <Grid container spacing={{ xl: 6, sm: 4, xs: 3 }}>
            <Grid item data-aos="fade-up" lg={4} md={6} xs={12}>
              <Card className="it-consulting-card">
                <CardContent>
                  <img
                    loading="lazy"
                    src={images.ToThePointSolutions}
                    alt="To-the-Point Solutions"
                  />
                  <Typography variant="h4" component="span">
                    01
                  </Typography>
                  <Typography variant="h6" component="h6">
                    It should be Brief
                  </Typography>
                  <Typography variant="body2" component="p">
                    We, at unique it solution, provide succinct, focused
                    solutions for business problems and objectives. This
                    involves an initial assessment of how you currently use IT
                    resources and what new functionalities are required.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              data-aos="fade-up"
              data-aos-delay="100"
              lg={4}
              md={6}
              xs={12}
            >
              <Card className="it-consulting-card">
                <CardContent>
                  <img
                    loading="lazy"
                    src={images.CostEffectiveness}
                    alt="Cost-Effectiveness"
                  />
                  <Typography variant="h4" component="span">
                    02
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Cost Savings
                  </Typography>
                  <Typography variant="body2" component="p">
                    Our consulting services aim to strike a balance between
                    performance improvement and cost-cutting through careful
                    evaluation of costs and benefits. The best options for
                    companies are based on the potential benefit derived from
                    any level of investment.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              data-aos="fade-up"
              data-aos-delay="200"
              lg={4}
              md={6}
              xs={12}
            >
              <Card className="it-consulting-card">
                <CardContent>
                  <img
                    loading="lazy"
                    src={images.BestCollaboration}
                    alt="Best Collaboration"
                  />
                  <Typography variant="h4" component="span">
                    03
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Collaboration at its Best
                  </Typography>
                  <Typography variant="body2" component="p">
                    We strive for open communication with your team so that we
                    can better understand your needs. This approach ensures that
                    our solutions align well with your strategic objectives
                    while facilitating mutual understanding among teammates.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item data-aos="fade-up" lg={4} md={6} xs={12}>
              <Card className="it-consulting-card">
                <CardContent>
                  <img
                    loading="lazy"
                    src={images.BusinessAnalysis}
                    alt="Business Analysis"
                  />
                  <Typography variant="h4" component="span">
                    04
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Business Examination
                  </Typography>
                  <Typography variant="body2" component="p">
                    To find inefficiencies or reasons why a system isn’t working
                    as expected, we use advanced tools and methods to analyze
                    business processes, workflows, and IT systems.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              data-aos="fade-up"
              data-aos-delay="100"
              lg={4}
              md={6}
              xs={12}
            >
              <Card className="it-consulting-card">
                <CardContent>
                  <img
                    loading="lazy"
                    src={images.AnalyticalInsights}
                    alt="Analytical Insights"
                  />
                  <Typography variant="h4" component="span">
                    05
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Analysis Facts
                  </Typography>
                  <Typography variant="body2" component="p">
                    From the process data, we derive actionable insights that
                    guide us in making recommendations related to our
                    strategies. This forms the basis for tailored solutions that
                    can be scaled up going forward without changing anything
                    else.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              data-aos="fade-up"
              data-aos-delay="200"
              lg={4}
              md={6}
              xs={12}
            >
              <Card className="it-consulting-card">
                <CardContent>
                  <img
                    loading="lazy"
                    src={images.ImplementationExcellence}
                    alt="To-the-Point Solutions"
                  />
                  <Typography variant="h4" component="span">
                    06
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Excellent Implementation
                  </Typography>
                  <Typography variant="body2" component="p">
                    After suggesting what you have done wrong or right, we walk
                    with you through the implementation stage. Thus, we aim to
                    seamlessly integrate these innovations into existing
                    structures with minimum disturbance or interruption of
                    services by ensuring they positively impact your operations
                    in general terms.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item data-aos="fade-up" lg={4} md={6} xs={12}>
              <Card className="it-consulting-card">
                <CardContent>
                  <img
                    loading="lazy"
                    src={images.ContinuousImprovement}
                    alt="Continuous Improvement"
                  />
                  <Typography variant="h4" component="span">
                    07
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Continuous Enhancement
                  </Typography>
                  <Typography variant="body2" component="p">
                    We believe in continuous monitoring for improvement
                    purposes. Thus, tracking performance metrics and gathering
                    feedback will allow us to iterate upon the solution and
                    continue succeeding despite changing business conditions.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              data-aos="fade-up"
              data-aos-delay="100"
              lg={4}
              md={6}
              xs={12}
            >
              <Card className="it-consulting-card">
                <CardContent>
                  <img
                    loading="lazy"
                    src={images.ConsultingSolution}
                    alt="Consulting for the Best Solution"
                  />
                  <Typography variant="h4" component="span">
                    08
                  </Typography>
                  <Typography variant="h6" component="h6">
                    The Best Advice On Consulting
                  </Typography>
                  <Typography variant="body2" component="p">
                    We provide expert advice at every step of the way, acting as
                    your trusted partner. From initial evaluation to solution
                    implementation and subsequent stages, we will help you to
                    navigate through complexities for business growth.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </section>
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default ITConsulting;
