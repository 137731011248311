import {
  Breadcrumbs,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

interface breadcrumbsProps {
  link: string;
}

const NewsAndEntertainment: React.FC<breadcrumbsProps> = ({ link }) => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "Does Unique IT Solution provide solutions for live news updates?",
      answer: "Unique IT Solution offers advanced content management systems that streamline news article creation, editing, and publishing.Our tools keep content organized, up-to-date, and easily accessible.With user friendly interfaces and automated workflows, managing your news platform becomes efficient and effective.This ensures timely updates and a seamless experience for your audience",
    },
    {
      id: "panel2",
      question: "How does Unique IT Solution enhance social media interaction through their digital platform?",
      answer: "Unique IT Solution’s Unified Digital Platform ensures that your website is efficiently connected with social media. This allows for smooth interaction and integration, improving your online presence and user engagement."
    },
    {
      id: "panel3",
      question: "How can Unique IT Solution improve user engagement on a news website?",
      answer: " With Unique IT Solution, user engagement is elevated through the addition of interactive features such as polls and quizzes. Our approach includes utilizing social media to provide preferred content and timely updates, fostering a dedicated and engaged audience."
    },
    {
      id: "panel4",
      question: "How does Unique IT Solution handle site scalability?",
      answer: "Leverage Unique IT Solution for scalable solutions that effortlessly manage expanding traffic and content needs, keeping your site performant and reliable throughout its growth."
    },
    {
      id: "panel5",
      question: "How does Unique IT Solution ensure the security of my news site?",
      answer: "With Unique IT Solution, your site’s security is our top priority. We use advanced encryption, secure login protocols, and consistent security updates to safeguard against threats and unauthorized access."

    },
  ]
  return (
    <>
      <Helmet>
        <title>News & Entertainment | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner page-banner industry-details-banner"
        style={{ backgroundImage: `url(${images.NewsAndEntertainmentBanner})` }}
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <Breadcrumbs
              separator={
                <img
                  loading="lazy"
                  src={images.CaretRightWhite}
                  alt="Caret Right"
                />
              }
              data-aos="fade-up"
            >
              <Link to={`/${link}`} title={link}>
                {link.charAt(0).toUpperCase() + link.slice(1)}
              </Link>
              <Typography>News & Entertainment </Typography>
            </Breadcrumbs>
            <Typography
              component="h2"
              variant="h2"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              News & Entertainment
            </Typography>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        {/* industry problems start */}
        <section className="industry-problems mb-9">
          <Typography
            variant="body1"
            component="p"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Small businesses, particularly social media news pages with a
            sizable audience, encounter several critical issues in today’s
            rapidly changing news and entertainment environment. Our business
            analyst team and industry experts have identified these main areas
            that need strategic focus:
          </Typography>
          <Chip
            icon={<img loading="lazy" src={images.Question} alt="Question" />}
            label="Industry Problems"
            variant="outlined"
            color="error"
            className="mb-4"
            data-aos="fade-up"
            data-aos-delay="300"
          />
          <Typography
            variant="h3"
            align="center"
            component="h3"
            className="pb-7"
            data-aos="fade-up"
          >
            News & Entertainment Industries Challenges
          </Typography>
          <Grid container spacing={{ lg: 5, xs: 4 }}>
            <Grid item md={6} xs={12} data-aos="fade-up">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    01
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Build a Strong Digital Platform
                  </Typography>
                  <Typography variant="body2" component="span">
                    Some news pages perform well on social media, but most have
                    no independent website. This can enhance credibility, reach,
                    and users’ experience when a separate dedicated online
                    environment is developed. On the same note, social media
                    sharing features and attractive layouts through social media
                    optimization (SMO) assist in sharing your content.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up" data-aos-delay="100">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    02
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Keep Content Consistent and on Schedule
                  </Typography>
                  <Typography variant="body2" component="span">
                    They need to ensure that there is a constant supply of good
                    content. The incorporation of sophisticated scheduling tools
                    makes it possible to post news at the right time and keep
                    the audience active with constant posts.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    03
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Make Money Through Different Channels
                  </Typography>
                  <Typography variant="body2" component="span">
                    Monetizing ads and brands is usually not easy, and this is
                    one of the difficulties. Several ideas can be used in the
                    generation of revenues to support and expand your online
                    presence.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up" data-aos-delay="100">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    04
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Increase User Engagement
                  </Typography>
                  <Typography variant="body2" component="span">
                    Thus, the audience should be maintained and expanded by
                    constant interaction with them with the help of bright and
                    interesting content, individual approaches, and timely
                    updates. Loyalty can be ensured to casual visitors through
                    innovative strategies.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    05
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Improve Operational Efficiency
                  </Typography>
                  <Typography variant="body2" component="span">
                    Staff management, news updates, and organization of the
                    online work environment are crucial. Long-term success
                    requires efficient mechanisms for job posting, event
                    updates, and analysis of users’ interactions.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up" data-aos-delay="100">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    06
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Assign Access and Responsibilities
                  </Typography>
                  <Typography variant="body2" component="span">
                    It is important to assign specific access and duties to the
                    staff members to enhance the execution of tasks for each
                    sector of the business.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </section>
        {/* industry problems end */}
      </Container>
      {/* industry solutions start */}
      <section
        className="industry-solutions outer-card mb-9"
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <Chip
            icon={<img loading="lazy" src={images.Bulb} alt="Bulb" />}
            label="Our Solutions"
            variant="outlined"
            color="success"
            className="mb-4"
            data-aos="fade-up"
          />
          <Typography
            variant="h3"
            align="center"
            component="h3"
            className="pb-7"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Our Solution
          </Typography>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  variant="h5"
                  className="pb-3"
                  component="h5"
                  data-aos="fade-up"
                >
                  Unified Digital Platform
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Our websites are integrated where your content is and they
                  efficiently interact with social media. This enhances your
                  reliability and usability for users with beautiful, responsive
                  layouts. We also incorporate Social Media Optimization to
                  increase its prominence and activity.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Content Integration
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Design Excellence
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    SMO Integration
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Content Calendar
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Automated Publishing
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    CMS Benefits
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="content">
                <Typography
                  variant="h5"
                  className="pb-3"
                  component="h5"
                  data-aos="fade-up"
                >
                  Advanced Content Management
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  These are scheduling tools and a Content Management System
                  (CMS). This also helps you have a regular update and automated
                  publishing and performance evaluation to minimize the chances
                  of having wrong information and, at the same time, help you
                  maintain your audience.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  variant="h5"
                  data-aos="fade-up"
                  className="pb-3"
                  component="h5"
                >
                  Innovative Monetization Strategies
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Some of the ways we monetize the site include placing of
                  adverts, sponsored content, and associate marketing. We
                  organize the ads, assist with brands, and optimize the revenue
                  with the help of analytics.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Advertising Solutions
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Sponsored Content
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Affiliate Marketing
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Analytics Insights
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Interactive Features
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Personalized Experiences
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Real-Time Updates
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="content">
                <Typography
                  variant="h5"
                  data-aos="fade-up"
                  className="pb-3"
                  component="h5"
                >
                  Enhanced User Engagement
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  To increase the level of user engagement, we introduce
                  elements such as polls and quizzes. Social media also ensures
                  that the audience is provided with content that they prefer
                  and real-time updates to maintain their interest and create a
                  fan base.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }}>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  variant="h5"
                  data-aos="fade-up"
                  className="pb-3"
                  component="h5"
                >
                  Streamlined Operations and Access Management
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  To help reduce complexity, we have provided a platform for
                  managing news, jobs, and events. It determines who has access
                  to what, and as such, it regulates access to tasks, thus
                  maintaining the security of the tasks. An admin dashboard is
                  useful in the monitoring of an organization and increases the
                  rate of overall efficiency, scheduling, and data security.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Unified Platform
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Efficient Scheduling
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Performance Monitoring
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Admin Dashboard
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Role-Based Access
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </section>
      {/* industry solutions end */}
      <Container maxWidth="xl">
        {/* solution impact start */}
        <section className="solution-impact mb-9" data-aos="fade-in">
          <img
            loading="lazy"
            src={images.DashBorderLeft}
            className="left"
            alt="Border"
          />
          <img
            loading="lazy"
            src={images.DashBorderRight}
            className="right"
            alt="Border"
          />
          <Typography component="h4" variant="h4" data-aos="fade-up">
            Impact
          </Typography>
          <ul data-aos="fade-up">
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                unique it solution have helped to develop a social media
                presence for news pages and increase their effectiveness through
                unified presence and professional SMO. Revenue has significantly
                risen due to other monetization techniques, such as targeting
                advertisements, paid content, and associate marketing.
              </Typography>
            </li>
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                The general efficiency has also been enhanced, especially in
                areas that used to involve manual work, and the efficiency
                gained ranges between 80-90%. Increased efficiency of the work
                and availability of special software for content management has
                improved work organization.
              </Typography>
            </li>
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                Operational efficiency has improved dramatically, with manual
                tasks reduced by up to 90%. Streamlined operations and content
                management tools have optimized productivity and resource
                allocation. Comprehensive analytics reports provide actionable
                insights, supporting data-driven decision-making.
              </Typography>
            </li>
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                There is higher audience interaction, which has further
                influenced audience loyalty. Engagement tools and customizations
                help to make using the application a lot more enjoyable.
                Detailed analytical reports help to make informed decisions and
                ensure continuous growth in the context of the digital news
                market competition.
              </Typography>
            </li>
          </ul>
        </section>
        {/* solution impact end */}
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default NewsAndEntertainment;
