import { LinearProgress } from "@mui/material";
import React from "react";
import images from "../../assets/images";

interface props {
  progress: number;
}

const SiteLoader: React.FC<props> = ({ progress }) => {
  return (
    <>
      <div className="loader">
        <img src={images.Logo} alt="Unique IT Solution" />
        <LinearProgress variant="determinate" value={progress} />
      </div>
    </>
  );
};

export default SiteLoader;
