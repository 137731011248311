import React from "react";
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

interface breadcrumbsProps {
  link: string;
}

const UIUXDesign: React.FC<breadcrumbsProps> = ({ link }) => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "What is UI/UX design?",
      answer: "UI and UX design are key to making digital products both attractive and easy to use. Unique IT Solution excels in these areas, ensuring that designs look great and are intuitive. UI design handles the visual aspects, while UX design focuses on the user journey. Together, they make for a smooth and satisfying user experience.",
    },
    {
      id: "panel2",
      question: "What are the key technical parameters to consider for effective UI/UX design?",
      answer: "We use Parameter For systamatic work on UI/UX project requirements",
      parameter: [
        {
          Consistency: "Maintain uniformity in design elements and interactions for a cohesive experience.",
          Simplicity: "Focus on essential features to reduce complexity and improve navigation.",
          Affordance: "Design elements to naturally indicate their functionality, like clickable buttons.",
          Accessibility: "Ensure inclusivity for users with disabilities and support various input methods.",
          UserCenteredDesign: "Provide control over actions with features like undo/redo and customizable settings.",
          ResponsiveDesign: "Ensure the interface works well on all devices with flexible layouts and media queries."
        }
      ]
    },
    {
      id: "panel3",
      question: "Why is UI/UX design important for my business?",
      answer: "Effective UI/UX design is crucial for a great user experience, boosting customer satisfaction and retention. At Unique IT Solution, we focus on making designs that are visually appealing and easy to use. Good UI/UX design reduces bounce rates, enhances usability, and increases conversions for your business."
    },
    {
      id: "panel4",
      question: "How long does a typical UI/UX design project take?",
      answer: "The timeline for a UI/UX design project with Unique IT Solution depends on the project's complexity. Typically, it takes a few weeks to several months. We set clear milestones to ensure timely completion and meet your goals."
    },
    {
      id: "panel5",
      question: "How can I get in touch with Unique IT Solution for UI/UX design services?",
      answer: "To contact Unique IT Solution for UI/UX design services, visit our website or reach us by phone or email. We offer a consultation to discuss your project and answer your questions."
    },
  ]
  return (
    <>
      <Helmet>
        <title>UI/UX Design | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner service-details-banner bg-light-blue"
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <div className="left">
              <Breadcrumbs
                separator={
                  <img
                    loading="lazy"
                    src={images.CaretRight}
                    alt="Caret Right"
                  />
                }
                data-aos="fade-up"
              >
                <Link to={`/${link}`} title={link}>
                  {link.charAt(0).toUpperCase() + link.slice(1)}
                </Link>
                <Typography>UI/UX Design</Typography>
              </Breadcrumbs>
              <Typography
                component="h2"
                variant="h2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                UI/UX Design
              </Typography>
              <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                <Button
                  variant="contained"
                  color="primary"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  title="Get A Quote"
                >
                  <span className="text">Get A Quote</span>
                </Button>
              </Link>
            </div>
            <div className="right" data-aos="fade-up">
              <img
                loading="lazy"
                src={images.UIUXDesignBannerImage}
                alt="UI/UX Design"
                width="483"
                height="473"
              />
            </div>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        <section className="service-details-content">
          <Typography component="p" variant="body1" data-aos="fade-up">
            Firstly, we begin our UI/UX design process by conducting in-depth
            user research into demographics, behaviors, and requirements. This
            follows with intuitive user flows along with wireframes, which are
            prioritized for functionality purposes. Additionally, we create
            high-fidelity mockups of designs that polish the visual side of the
            applications, aiming at making the user experience more seamless and
            engaging. Iterative testing, as well as user feedback, enhances
            continuous improvement, hence producing refined UI/UX that aligns
            well with all your business objectives as well as those of the
            users.
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            align="center"
            data-aos="fade-up"
            className="pb-7"
          >
            UI/UX Design Process
          </Typography>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.UserCenteredDesign}
                  alt="User-Centered Design Approach"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  User-Centered Design Approach
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  We analyze interviews, surveys, plus analytical data to obtain
                  insights on user demographics, behaviors, and requirements,
                  thus guiding design decisions. The findings inform specific
                  personas development for each design decision made. These maps
                  help determine how an app should be able to meet every need a
                  customer has while optimizing their user experience.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Wireframing and Prototyping
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  For mobile and web app development, we begin with
                  brainstorming or sketching to outline the structure and
                  features. Then, we create detailed wireframes for layouts and
                  navigation, and build an interactive prototype to test
                  usability and gather feedback. This process helps refine the
                  design before actual development starts.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.Prototyping}
                  alt="Wireframing and Prototyping"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.VisualDesign}
                  alt="Visual Design and Brand Integration"
                />
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Visual Design and Brand Integration
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Develop a unified system of colours, typography, and other
                  visuals that reflect your brand identity. Using fidelity
                  mockups produced from wireframes ensures uniformity in UI
                  representation and clarity throughout interfaces that display
                  content. It is also recommended that priority be given to
                  improved accessibility elements such as contrast ratios and
                  font sizes to enhance usability.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5} className="mb-9 section-details">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <div className="content">
                <Typography
                  component="h5"
                  variant="h5"
                  className="pb-3"
                  data-aos="fade-up"
                >
                  Usability Testing and Iterative Refinement
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Test the app with real users to check usability and
                  navigation. Use their feedback to make design improvements.
                  Continue to enhance the UI/UX through regular updates based on
                  analytics and user data.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="image-wrapper" data-aos="fade-up">
                <img
                  loading="lazy"
                  src={images.UsabilityTesting}
                  alt="Usability Testing and Iterative Refinement"
                />
              </div>
            </Grid>
          </Grid>
        </section>
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default UIUXDesign;
