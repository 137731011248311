import {
  Breadcrumbs,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

interface breadcrumbsProps {
  link: string;
}

const TelecommunicationMedia: React.FC<breadcrumbsProps> = ({ link }) => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "What features are included in Unique IT Solution’s telecommunications systems?",
      answer: "Unique IT Solution’s telecommunications systems feature unified messaging for all-in-one communication, VoIP for clear and affordable voice calls, real-time collaboration tools for seamless teamwork, and advanced network monitoring to ensure optimal performance. These features collectively enhance communication and streamline operations.",
    },
    {
      id: "panel2",
      question: "What telecommunications solutions does Unique IT Solution offer?",
      answer: "Unique IT Solution provides a range of telecommunications solutions, including advanced communication systems, media management tools, and network optimization services. Our solutions are designed to enhance connectivity, improve media distribution, and streamline communication processes."
    },
    {
      id: "panel3",
      question: "What network optimization services does Unique IT Solution provide?",
      answer: "Unique IT Solution provides network optimization services that enhance the performance and reliability of telecommunications networks. Our services include network monitoring, performance tuning, and capacity planning to ensure optimal network efficiency and user experience."
    },
    {
      id: "panel4",
      question: "How does Unique IT Solution integrate with existing telecommunications systems?",
      answer: "With Unique IT Solution, integrating new tools into your existing telecommunications systems is effortless. Our solutions are designed to be compatible and flexible, ensuring that data exchange is seamless and your operations remain uninterrupted."
    },
    {
      id: "panel5",
      question: "How does Unique IT Solution ensure network security for telecommunications companies?",
      answer: "Unique IT Solution prioritizes network security by implementing cutting-edge security protocols and encryption technologies. We conduct regular security audits to identify and address potential vulnerabilities. Our comprehensive approach ensures that telecommunications networks are protected from threats and operate securely, providing peace of mind for our clients."
    },
  ]
  return (
    <>
      <Helmet>
        <title>Telecommunication Media | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner page-banner industry-details-banner"
        style={{
          backgroundImage: `url(${images.TelecommunicationMediaBanner})`,
        }}
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <Breadcrumbs
              separator={
                <img
                  loading="lazy"
                  src={images.CaretRightWhite}
                  alt="Caret Right"
                />
              }
              data-aos="fade-up"
            >
              <Link to={`/${link}`} title={link}>
                {link.charAt(0).toUpperCase() + link.slice(1)}
              </Link>
              <Typography>Telecommunication Industry</Typography>
            </Breadcrumbs>
            <Typography
              component="h2"
              variant="h2"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Telecommunication Industry
            </Typography>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        {/* industry problems start */}
        <section className="industry-problems mb-9">
          <Chip
            icon={<img loading="lazy" src={images.Question} alt="Question" />}
            label="Industry Problems"
            variant="outlined"
            color="error"
            className="mb-4"
            data-aos="fade-up"
            data-aos-delay="200"
          />
          <Typography
            variant="h3"
            align="center"
            component="h3"
            className="pb-7"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Telecommunication Industry Challenges
          </Typography>
          <Grid container spacing={{ lg: 5, xs: 4 }}>
            <Grid item md={6} xs={12} data-aos="fade-up" data-aos-delay="400">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    01
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Device Management
                  </Typography>
                  <Typography variant="body2" component="span">
                    VoIP equipment is the device used to facilitate
                    communication over the internet and includes IP phones, VoIP
                    adapters, and softphones. A SIP server controls the
                    signaling of VoIP calls, which includes the registration of
                    the device, call establishment, and session control.
                    <br />
                    <br /> A PBX IP device is a communication system that works
                    on the internet platform and controls internal and external
                    calls through call transferring, voice mail, and an
                    automatic answering system. <br />
                    <br />
                    It is necessary to make periodic inspections of PBX, VoIP,
                    and SIP devices and their timely maintenance. This includes
                    tracking updates, fixing connection problems, and even
                    manual examinations, which can be very time-consuming as
                    well as cumbersome.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up" data-aos-delay="500">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    02
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Location and Connectivity
                  </Typography>
                  <Typography variant="body2" component="span">
                    In regards to the physical location of devices, it is
                    time-consuming to manage, and this slows down the
                    troubleshooting process. The configuration and access should
                    be correct and allowed at different places as required at
                    times.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    03
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Network and Satellite Management
                  </Typography>
                  <Typography variant="body2" component="span">
                    Coordinating dealer's networks, resellers and employee
                    access entails performance tracking and connectivity
                    problems. Expertise is required to fill satellite input
                    reports, device condition checks and oversight of dish
                    network installation. This consists of proper positioning
                    signal intensity as well as managing the issues associated
                    with the satellite communication networks.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up" data-aos-delay="100">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    04
                  </Typography>
                  <Typography variant="h6" component="h6">
                    VoIP and SIP Issues
                  </Typography>
                  <Typography variant="body2" component="span">
                    Routing of calls in environments that include a number of
                    VoIP and SIP servers requires accuracy. When it comes to
                    communication reliability, latency, jitter, and packet loss
                    should be managed properly. Indeed, it is quite difficult to
                    track all devices successfully employing standard APIs.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    05
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Support and Maintenance
                  </Typography>
                  <Typography variant="body2" component="span">
                    The help desk plays an important role in support and
                    maintenance, and thus, efficient management is required.
                    Automated error reporting and maintenance procedures help
                    manage and simplify troubleshooting, minimize the time in
                    which clients cannot use the service, and guarantee a
                    constant flow of service. Still, controlling all devices
                    from a single system is not easy.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </section>
        {/* industry problems end */}
      </Container>
      {/* industry solutions start */}
      <section
        className="industry-solutions outer-card mb-9"
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <Chip
            icon={<img loading="lazy" src={images.Bulb} alt="Bulb" />}
            label="Our Solutions"
            variant="outlined"
            color="success"
            className="mb-4"
            data-aos="fade-up"
          />
          <Typography
            variant="h3"
            align="center"
            component="h3"
            className="pb-4"
            data-aos="fade-up"
          >
            Our Solutions
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            component="p"
            className="pb-7 subtext"
            data-aos="fade-up"
          >
            To tackle these multiple issues, unique it solution has created an
            extensive system designed specifically for the telecommunications
            sector. Our solution focuses on:
          </Typography>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  variant="h5"
                  className="pb-3"
                  component="h5"
                  data-aos="fade-up"
                >
                  Device Iteration and Inspection
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  unique it solution offer a holistic view of device iteration
                  and inspection. It systematically scans each device’s
                  parameter to check for its working status. In the case that a
                  device has been classified as being defective, the system is
                  programmed to link to the device’s manager or dealer for
                  repairs. It also has measures for handling errors that help in
                  their quick correction hence reducing the time taken.
                  Different means are used to ensure that these issues are well
                  handled and managed in the communication process.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Iterates Through Devices
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Functional Status Check
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Error Detection
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Error Resolution
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Communication Methods
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Centralized Registration
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Device API Integration
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    User Management
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Admin and Reseller Access
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="content">
                <Typography
                  variant="h5"
                  className="pb-3"
                  component="h5"
                  data-aos="fade-up"
                >
                  Device Registration and Management
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  We consolidate device registration around a single interface
                  and utilize Grandstream’s UCM portal APIs for device
                  management. Users can manage VoIP and SIP servers directly
                  from the portal. Moreover, the platform admins can manage the
                  device resellers, and the latter also have the option to
                  monitor their devices. Thus, the flow of handling the devices
                  is maintained.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  variant="h5"
                  data-aos="fade-up"
                  className="pb-3"
                  component="h5"
                >
                  Automated Monitoring
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Our system guarantees constant monitoring of devices by using
                  such processes. Device status is updated every 30 minutes
                  using cron jobs, while web scraping and frequent API scans
                  give real-time status. This is also applied to set-top boxes
                  to maintain consistency of supervision and control.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Regular Updates
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Web Scraping
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    API Checks
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Set-Top Box Monitoring
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }}>
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Ticket Generation
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Ticket Approval
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Lifecycle Tracking
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Staff Communication
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="content">
                <Typography
                  variant="h5"
                  data-aos="fade-up"
                  className="pb-3"
                  component="h5"
                >
                  Help Desk Support
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  The help desk feature makes support more efficient by letting
                  users and admins create support tickets. Thus, the admins can
                  approve and monitor the tickets at each stage of their
                  processing to guarantee their effective resolution. It also
                  tracks staff responses and all other associated information,
                  giving the system a complete support management package.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      {/* industry solutions end */}
      <Container maxWidth="xl">
        {/* solution impact start */}
        <section className="solution-impact mb-9" data-aos="fade-in">
          <img
            loading="lazy"
            src={images.DashBorderLeft}
            className="left"
            alt="Border"
          />
          <img
            loading="lazy"
            src={images.DashBorderRight}
            className="right"
            alt="Border"
          />
          <Typography component="h4" variant="h4" data-aos="fade-up">
            Impact
          </Typography>
          <ul data-aos="fade-up">
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                At unique it solution, we create a paradigm shift in the way
                business is done in the telecommunication sector. Through
                automation, device management, inspection, and error resolution
                are accomplished with minimal human interference, and hence, the
                cost of operations is cut. Centralized management of devices and
                automated monitoring and control offers extensive coverage and
                uniformity of the devices’ functioning and problem
                identification.
              </Typography>
            </li>
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                Our integrated help desk helps to support the unified model and,
                therefore, decreases direct user calls. This efficiency enables
                businesses to allocate resources efficiently since the emphasis
                is on growth.
              </Typography>
            </li>
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                In the long run, our platform optimizes the operational cycles,
                reduces manpower expenses, and increases growth by increasing
                efficiency, reliability, and service. It allows
                telecommunications businesses to be successful in a competitive
                environment since it helps them manage their infrastructures and
                support systems.
              </Typography>
            </li>
          </ul>
        </section>
        {/* solution impact end */}
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default TelecommunicationMedia;
