import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import ServiceCard from "../../components/ServiceCard";
import services from "../Services/Services.json";
import { Helmet } from "react-helmet-async";

interface serviceCardProps {
  icon: string;
  title: string;
  description: string;
  link: string;
  iconBg: string;
}

const Services: React.FC = () => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "How to choose right custom software development service company?",
      answer: "Selecting the right custom software development company seems like a complicated process but it's unique straightforward. To choose the right outsourcing partner fro your project, you must understand theie development process, past experiance of successfully executing similar software projects and expertise in teh technology stack.",
    },
    {
      id: "panel2",
      question: "What is the software development",
      answer: "Software development is the process of creating, designing, testing, and maintaining software programs and applications.Custom software can be scaled and adapted as your business grows, ensuring it remains relevant and useful"
    },
    {
      id: "panel3",
      question: "What are the stages software development.",
      answer: "The software development life cycle divided into 5 major stages.This start from requirement gathering,Software designing,Coding,Testing,Deployment.And Following all above state cerate the relaible and flexible software."
    },
    {
      id: "panel4",
      question: "What kind of Teachnologies are used for develop custom software",
      answer: "We use a variety of technologies depending on your project needs. This includes programming languages like Java, Python, and JavaScript, along with web technologies such as HTML, CSS, and frameworks like React or Angular. For back-end development, we use tools like Node.js, .NET, or PHP, and databases such as MySQL or MongoDB. Cloud platforms like AWS or Azure are also used to ensure scalability and reliability."
    },
    {
      id: "panel5",
      question: "What about the maintance of our Service",
      answer: "We provide comprehensive maintenance for our services, which includes regular updates, bug fixes, and performance improvements. Our maintenance ensures that your software continues to operate smoothly and remains up-to-date with the latest technologies and security standards."
    },
  ]
  return (
    <>
      <Helmet>
        <title>Services | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner page-banner mb-9"
        style={{ backgroundImage: `url(${images.ServicesBanner})` }}
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <Typography
              component="h2"
              variant="h2"
              data-aos="fade-up"
              data-aos-delay="100"
              className="pb-3"
            >
              Our Services
            </Typography>
            <Typography
              component="p"
              variant="body1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Top-tier Web Development, Custom Software Solutions, and Mobile
              App Development using cutting-edge technologies for exceptional
              results.
            </Typography>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl" className="mb-9">
        <Grid container spacing={{ xl: 6, sm: 4, xs: 3 }}>
          {services.map((service: serviceCardProps, index: number) => (
            <Grid key={index} item lg={4} md={6} xs={12} data-aos="fade-up">
              <ServiceCard
                icon={images[service.icon]}
                title={service.title}
                description={service.description}
                link={service.link}
                iconBg={service.iconBg}
                from="services"
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default Services;
