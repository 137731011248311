import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import images from "../../../assets/images";
// import FAQ from "../../components/FAQ";
import IndustryCard from "../../components/IndustryCard";
import RequestProposal from "../../components/RequestProposal";
import industries from "../Industries/Industries.json";
import { Helmet } from "react-helmet-async";

interface industryCardProps {
  bgImage: string;
  title: string;
  link: string;
}

const Industries: React.FC = () => {
  // const faqDataPage = [
  //   {
  //     id: "panel1",
  //     question: "What industries does Unique IT Solution serve?",
  //     answer: "At Unique IT Solution, we provide tailored technology solutions for diverse industries, including Hospitality, Telecomunication Media,retail & Ecommerce, and News & Media. Our approach ensures that each sector receives effective and efficient solutions tailored to its specific needs.",
  //   },
  //   {
  //     id: "panel2",
  //     question: "Which technology we use for development?",
  //     answer: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
  //   },
  //   {
  //     id: "panel3",
  //     question: "How would you provide custom software development?",
  //     answer: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
  //   },
  //   {
  //     id: "panel4",
  //     question: "What about maintence?",
  //     answer: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
  //   },
  //   {
  //     id: "panel5",
  //     question: "How to contact us?",
  //     answer: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
  //   },
  // ]
  return (
    <>
      <Helmet>
        <title>Industries | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner page-banner mb-9"
        style={{ backgroundImage: `url(${images.IndustriesBanner})` }}
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <Typography
              component="h2"
              variant="h2"
              data-aos="fade-up"
              data-aos-delay="100"
              className="pb-3"
            >
              Industries
            </Typography>
            <Typography
              component="p"
              variant="body1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Explore how Unique IT Solution has shaped business by offering
              innovative and technology driven software solution.
            </Typography>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl" className="mb-9">
        <Grid container spacing={{ xl: 6, sm: 4, xs: 3 }}>
          {industries.map((industry: industryCardProps, index: number) => (
            <Grid key={index} item lg={4} md={6} xs={12} data-aos="fade-up">
              <IndustryCard
                bgImage={images[industry.bgImage]}
                title={industry.title}
                link={industry.link}
                from="industries"
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* FAQ start */}
      {/* <FAQ faqData={faqDataPage} /> */}
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default Industries;
