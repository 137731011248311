import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import images from "../../assets/images";

type AllowedFileTypes = ".doc" | ".pdf" | ".xls" | ".txt";

const allowedFileTypes: AllowedFileTypes[] = [".doc", ".pdf", ".xls", ".txt"];
const MAX_FILE_SIZE = 4;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE * 1024 * 1024;


interface FileUploadProps {
  onFileChange: (file: File | null) => void;
}
const FileUpload: React.FC<FileUploadProps> = ({ onFileChange }) => {
  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);


  useEffect(() => {
    onFileChange(file);
  }, [file, onFileChange]);
  
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (validateFile(droppedFile)) {
      setFile(droppedFile);
    }
  };

  const validateFile = (file: File): boolean => {
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    const isExtensionValid =
      fileExtension !== undefined &&
      allowedFileTypes.includes(`.${fileExtension}` as AllowedFileTypes);

    const isSizeValid = file.size <= MAX_FILE_SIZE_BYTES;

    if (!isExtensionValid) {
      alert(
        "Invalid file type. Please upload a .doc, .pdf, .xls, or .txt file."
      );
    } else if (!isSizeValid) {
      alert(`File size exceeds the 4 MB limit. Please upload a smaller file.`);
    }

    return isExtensionValid && isSizeValid;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedFile = e.target.files?.[0] || null;
    if (selectedFile && validateFile(selectedFile)) {
      setFile(selectedFile);
    }
  };

  const formatFileSize = (size: number): string => {
    if (size >= 1024 * 1024) {
      return (size / (1024 * 1024)).toFixed(2) + " MB";
    } else if (size >= 1024) {
      return (size / 1024).toFixed(2) + " KB";
    } else {
      return size + " bytes";
    }
  };

  const handleDelete = () => {
    setFile(null);
  };

  return (
    <>
      <div
        className={`file-upload ${isDragging ? "dragging" : ""}`}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {!file && (
          <>
            <img src={images.Upload} alt="Upload" />
            <p>
              Drag & drop here, or{" "}
              <label htmlFor="file-upload">choose file</label>
            </p>
            <span>Max size: 4 MB, Allowed Type: .doc, .pdf, .xls, .txt</span>
            <input
              accept=".doc,.pdf,.xls,.txt"
              onChange={handleFileChange}
              type="file"
              id="file-upload"
            />
          </>
        )}
        {file && (
          <>
            <img src={images.File} alt="Upload" />
            <p>{file.name}</p>
            <span>{formatFileSize(file.size)}</span>
            <IconButton size="small" onClick={handleDelete}>
              <img src={images.Delete} alt="Delete" />
              <img src={images.DeleteWhite} className="active" alt="Delete" />
            </IconButton>
          </>
        )}
      </div>
    </>
  );
};

export default FileUpload;
