import {
  Breadcrumbs,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

interface breadcrumbsProps {
  link: string;
}

const ProductionAndServiceAssurance: React.FC<breadcrumbsProps> = ({
  link,
}) => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "What production and service assurance solutions does Unique IT Solution provide?",
      answer: "Unique IT Solution offers advanced production and inventory management systems featuring live sensors and real-time reporting for accurate inventory tracking. Our AI-driven cameras ensure high-quality control by detecting defects in new products. These solutions streamline production, enhance stock accuracy, and uphold superior product standards.",
    },
    {
      id: "panel2",
      question: "What production and inventory management solutions does Unique IT Solution offer?",
      answer: "Unique IT Solution provides advanced production and inventory management systems that integrate live sensors and instant reporting. Our solutions ensure accurate stock management to prevent stockouts and overstocking, and utilize AI-driven cameras for quality checks on new products."
    },
    {
      id: "panel3",
      question: "How does Unique IT Solution handle service assurance in customer-facing environments?",
      answer: "Unique IT Solution ensures exceptional service assurance with advanced monitoring and quality control systems. We track service performance in real-time, analyze customer feedback, and maintain strict quality standards to enhance service delivery and customer satisfaction."
    },
    {
      id: "panel4",
      question: "What features are included in Unique IT Solution’s instant reporting system?",
      answer: "Features of Unique IT Solution’s instant reporting system include real-time inventory tracking, automated alerts for stock levels, detailed analytics, and customizable reports. These features provide valuable insights and help manage inventory efficiently."
    },
    {
      id: "panel5",
      question: "How does Unique IT Solution’s system contribute to cost savings in inventory management?",
      answer: " By preventing stockouts and overstocking through precise inventory tracking, Unique IT Solution’s system helps reduce carrying costs and minimize losses from excess stock. Accurate inventory management also improves operational efficiency and cost-effectiveness."
    },
  ]
  return (
    <>
      <Helmet>
        <title>Production & Service Assurance | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner page-banner industry-details-banner"
        style={{
          backgroundImage: `url(${images.ManufacturingAndProcessingFacilitiesBanner})`,
        }}
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <Breadcrumbs
              separator={
                <img
                  loading="lazy"
                  src={images.CaretRightWhite}
                  alt="Caret Right"
                />
              }
              data-aos="fade-up"
            >
              <Link to={`/${link}`} title={link}>
                {link.charAt(0).toUpperCase() + link.slice(1)}
              </Link>
              <Typography>Production and Service Assurance</Typography>
            </Breadcrumbs>
            <Typography
              component="h2"
              variant="h2"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Production and Service Assurance
            </Typography>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        {/* industry problems start */}
        <section className="industry-problems mb-9">
          <Chip
            icon={<img loading="lazy" src={images.Question} alt="Question" />}
            label="Industry Problems"
            variant="outlined"
            color="error"
            className="mb-4"
            data-aos="fade-up"
            data-aos-delay="200"
          />
          <Typography
            variant="h3"
            align="center"
            component="h3"
            className="pb-7"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Production and Service Assurance Challenges
          </Typography>
          <Grid container spacing={{ lg: 5, xs: 4 }}>
            <Grid item md={6} xs={12} data-aos="fade-up" data-aos-delay="400">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    01
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Production and Inventory Management
                  </Typography>
                  <Typography variant="body2" component="span">
                    One of the major issues is to manage production and
                    inventory. It is important to ensure that the various stock
                    levels are correct to help minimize the various stock outs
                    and also over stocking. High-quality checking mechanisms are
                    required to check the quality of the products being
                    produced. To a certain extent, the application of sensors
                    and regular reports is useful when it comes to monitoring
                    and management in real-time.
                  </Typography>
                  <ul>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Production Management
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Inventory Control
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">Stock Management</Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Quality Checking Systems
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Sensor Integration and Reporting
                      </Typography>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up" data-aos-delay="500">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    02
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Service and Ticket Management
                  </Typography>
                  <Typography variant="body2" component="span">
                    Managing service requests and tickets from both the users
                    and the staff is always a challenge. The use of tickets to
                    track issues through different stages helps in the timely
                    resolution and improvement of the quality of services.
                    Proper management of services and tickets is crucial for the
                    organization’s efficient functioning.
                  </Typography>
                  <ul>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Service Registration
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">Ticket Tracking</Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Multi-Stage Ticket Management
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        User and Staff End Coordination
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Workflow Optimization
                      </Typography>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    03
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Communication and Notification Systems
                  </Typography>
                  <Typography variant="body2" component="span">
                    Customer and stakeholder relations are an important aspect
                    that requires appropriate communication. Through the various
                    channels, people are informed, trust is established and
                    there is transparency in the service delivery.
                  </Typography>
                  <ul>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Multi-Channel Communication
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Customer Notifications
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Stakeholder Updates
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">Timely Messaging</Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Transparency in Service
                      </Typography>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up" data-aos-delay="100">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    04
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Dealer and Customer Relationship Management
                  </Typography>
                  <Typography variant="body2" component="span">
                    The operation of the business needs to manage dealers and
                    customers well. Timely responses to interactions and
                    inquiries enhance satisfaction levels and customer loyalty.
                    Effective management systems facilitate and ensure order and
                    efficiency in the relations between people.
                  </Typography>
                  <ul>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Dealer Management
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Customer Interaction
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Service Assignment
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">Query Handling</Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Relationship Building
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Loyalty Enhancement
                      </Typography>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    05
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Analytics and Reporting
                  </Typography>
                  <Typography variant="body2" component="span">
                    Clear and specific analysis and reporting are very important
                    when it comes to making intelligent decisions. Performance
                    data analysis assists in identifying areas of weakness and
                    strength of a business. This way, management is informed of
                    the matters and can take relevant action on time.
                  </Typography>
                  <ul>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Performance Analytics
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Detailed Reporting
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Data-Driven Decisions
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Continuous Improvement
                      </Typography>
                    </li>
                    <li>
                      <img
                        loading="lazy"
                        src={images.OutlinedCheckmarkPrimary}
                        alt="Checkmark"
                      />
                      <Typography component="span">
                        Proactive Management
                      </Typography>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </section>
        {/* industry problems end */}
      </Container>
      {/* industry solutions start */}
      <section
        className="industry-solutions outer-card mb-9"
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <Chip
            icon={<img loading="lazy" src={images.Bulb} alt="Bulb" />}
            label="Our Solutions"
            variant="outlined"
            color="success"
            className="mb-4"
            data-aos="fade-up"
          />
          <Typography
            variant="h3"
            align="center"
            component="h3"
            className="pb-7"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Our Solution
          </Typography>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  variant="h5"
                  className="pb-3"
                  component="h5"
                  data-aos="fade-up"
                >
                  Production and Inventory Management
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  We created a system that incorporates sensors that go live and
                  instant reporting. This ensures that there is proper stock
                  management so that there is no stock out or overstocking. We
                  also installed a quality check on the new products through the
                  use of cameras and AI to identify defects.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Real-time Sensor Integration
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Automated Reporting
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    AI-driven Quality Checking
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Defect Detection
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Mobile Application for Complaint Registration
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Admin Approval Process
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Dealer and Serviceman Assignment
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Multi-channel Notifications
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Status Updates with Images and Videos
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Service Tracking and Reporting
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="content">
                <Typography
                  variant="h5"
                  className="pb-3"
                  component="h5"
                  data-aos="fade-up"
                >
                  Service and Ticket Management
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  The service request and ticket functions are integrated into
                  our mobile app. It is easy to register complaints from the
                  users' side. The admin staff also has the role of reviewing
                  and assigning dealers and servicemen. These include the use of
                  WhatsApp and text message updates. Servicemen post pictures
                  and videos to indicate their status, which the administrators
                  check.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  variant="h5"
                  data-aos="fade-up"
                  className="pb-3"
                  component="h5"
                >
                  Communication and Notification Systems
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  We make provision for multiple media communication through
                  WhatsApp and text messages. This ensures that the customers
                  and the stakeholders are informed at every level and hence
                  enhances the level of transparency.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Multi-channel Communication
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Customer and Stakeholder Notifications
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Timely Updates
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Seamless Dealer and Customer Interactions
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Efficient Query Handling
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Service Assignment
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Customer Satisfaction and Loyalty Enhancement
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="content">
                <Typography
                  variant="h5"
                  data-aos="fade-up"
                  className="pb-3"
                  component="h5"
                >
                  Dealer and Customer Relationship Management
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  It is also useful in mobile applications where relationships
                  with dealers and customers can be handled efficiently. This
                  means that the firm is able to attend to customers' queries
                  more efficiently and provide services in a way that enhances
                  satisfaction and loyalty among customers.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }}>
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  variant="h5"
                  data-aos="fade-up"
                  className="pb-3"
                  component="h5"
                >
                  Analytics and Reporting
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Our system offers many reports and analytics. The generated
                  reports can be exported in different formats such as Excel,
                  PDF, or even CSV. This aids in decision-making and service
                  enhancement since it involves the use of data.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Detailed Performance Analytics
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Multi-format Report Generation
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Data-driven Decision-making
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Continuous Improvement
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Proactive Management
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </section>
      {/* industry solutions end */}
      <Container maxWidth="xl">
        {/* solution impact start */}
        <section className="solution-impact mb-9" data-aos="fade-in">
          <img
            loading="lazy"
            src={images.DashBorderLeft}
            className="left"
            alt="Border"
          />
          <img
            loading="lazy"
            src={images.DashBorderRight}
            className="right"
            alt="Border"
          />
          <Typography component="h4" variant="h4" data-aos="fade-up">
            Impact
          </Typography>
          <ul data-aos="fade-up">
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                Unique IT solutions have transformed the production and services
                guarantee to the clients. The new production and inventory
                control system has greatly helped in reducing lead time and
                stock holding. Real-time integration and the use of AI in
                quality control have provided quality assurance, hence
                increasing customer satisfaction and reducing returns.
              </Typography>
            </li>
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                With the help of the new service and ticket management
                application, request handling has become much more efficient.
                With the use of multi-channel notifications, the customers are
                kept informed throughout the service process, hence cutting down
                on the number of daily calls.
              </Typography>
            </li>
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                There has been an improvement in communication with the
                customers and the other stakeholders, hence improving trust and
                satisfaction levels. Dealer and customer relationship management
                has enhanced, and this has favored quicker query resolutions and
                loyalty.
              </Typography>
            </li>
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                BI and analytics have provided means for efficient
                decision-making and early problem-solving. This has led to
                efficiency and constant enhancement of service delivery.
              </Typography>
            </li>
          </ul>
        </section>
        {/* solution impact end */}
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default ProductionAndServiceAssurance;
