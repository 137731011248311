import {
  Breadcrumbs,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import images from "../../../assets/images";
import FAQ from "../../components/FAQ";
import RequestProposal from "../../components/RequestProposal";
import { Helmet } from "react-helmet-async";

interface breadcrumbsProps {
  link: string;
}

const FoodBeverages: React.FC<breadcrumbsProps> = ({ link }) => {
  const faqDataPage = [
    {
      id: "panel1",
      question: "How can Unique IT Solution improve the efficiency of Food & Beverage operations?",
      answer: "Unique IT Solution provides tailored software for the food and beverage industry, including inventory management, and workflow automation.Our solutions streamline operations, improve efficiency, and enhance the customer experience, helping you track sales, manage stock, and automate tasks with ease.",
    },
    {
      id: "panel2",
      question: "How does Unique IT Solution help with employee management in the food and beverage industry?",
      answer: "Unique IT Solution offers advanced employee management tools that streamline staff operations. Our system tracks working hours, manages shifts efficiently, and monitors productivity to optimize workforce performance. With real-time data and scheduling features, businesses can easily oversee staff activities, reduce scheduling conflicts, and enhance overall efficiency. These tools help ensure that your team operates smoothly and effectively, leading to improved business operations and employee satisfaction."
    },
    {
      id: "panel3",
      question: "How can Unique IT Solution help with inventory management for restaurants?",
      answer: "Unique IT Solution offers intelligent inventory management systems that monitor stock levels in real time.Our solutions help minimize waste and ensure you always have the ingredients you need by providing accurate, up- to - date inventory data.This proactive approach prevents shortages, optimizes stock usage, and supports efficient ordering, ultimately enhancing your operational efficiency and reducing costs."
    },
    {
      id: "panel4",
      question: "How can Unique IT Solution’s solutions improve customer loyalty?",
      answer: " Unique IT Solution boosts customer engagement with features like loyalty programs, personalized offers, and feedback tools. Our loyalty programs reward repeat customers, while personalized offers enhance their experience and encourage return visits. Feedback tools provide valuable insights for continuous improvement. These features help build strong customer relationships, drive loyalty, and increase repeat business."
    },
    {
      id: "panel5",
      question: "How does Unique IT Solution ensure food and beverage data security?",
      answer: "Unique IT Solution prioritizes data security by using advanced encryption to protect customer and transaction information. We ensure safe transactions with secure payment gateways and perform regular security updates and assessments. Our comprehensive approach safeguards data, preventing unauthorized access and fraud, while keeping you and your customers confident in its security."
    },
  ]
  return (
    <>
      <Helmet>
        <title>Food & Beverages | Unique IT Solution</title>
      </Helmet>

      {/* banner start */}
      <section
        className="banner page-banner industry-details-banner"
        style={{ backgroundImage: `url(${images.FoodBeveragesBanner})` }}
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <div className="banner-content">
            <Breadcrumbs
              separator={
                <img
                  loading="lazy"
                  src={images.CaretRightWhite}
                  alt="Caret Right"
                />
              }
              data-aos="fade-up"
            >
              <Link to={`/${link}`} title={link}>
                {link.charAt(0).toUpperCase() + link.slice(1)}
              </Link>
              <Typography>Food & Beverages</Typography>
            </Breadcrumbs>
            <Typography
              component="h2"
              variant="h2"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Food & Beverages
            </Typography>
          </div>
        </Container>
      </section>
      {/* banner end */}
      <Container maxWidth="xl">
        {/* industry problems start */}
        <section className="industry-problems mb-9">
          <Chip
            icon={<img loading="lazy" src={images.Question} alt="Question" />}
            label="Industry Problems"
            variant="outlined"
            color="error"
            className="mb-4"
            data-aos="fade-up"
            data-aos-delay="200"
          />
          <Typography
            variant="h3"
            align="center"
            component="h3"
            className="pb-7"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Problems in Hospitality Industries
          </Typography>
          <Grid container spacing={{ lg: 5, xs: 4 }}>
            <Grid item md={6} xs={12} data-aos="fade-up" data-aos-delay="400">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    01
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Intercommunication Calls in Hotels
                  </Typography>
                  <Typography variant="body2" component="span">
                    Outdated LAN-based methods hinder efficient internal
                    communication between hotel departments, causing delays in
                    guest service responses.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up" data-aos-delay="500">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    02
                  </Typography>
                  <Typography variant="h6" component="h6">
                    PBX and voIP Devices Management
                  </Typography>
                  <Typography variant="body2" component="span">
                    Managing PBX systems in hotels involves complex setup, high
                    maintenance costs, and integration challenges with modern
                    tools. voIP devices like cameras and intercoms require
                    robust network integration for security and guest
                    communication, posing technical compatibility issues.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    03
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Customer Relationship Management (CRM) Challenges
                  </Typography>
                  <Typography variant="body2" component="span">
                    Hotels struggle with ineffective systems for tracking guest
                    preferences and history, leading to impersonal service and
                    guest dissatisfaction. Poor follow-up on feedback and
                    inconsistent wake-up call services further diminish guest
                    experiences.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up" data-aos-delay="100">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    04
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Room Allocation and Management
                  </Typography>
                  <Typography variant="body2" component="span">
                    Inefficient check-in/out processes and inaccurate room
                    allocation practices contribute to operational
                    inefficiencies in hotels. Challenges in managing room shifts
                    and type changes impact guest satisfaction and operational
                    smoothness.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    05
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Staff Management
                  </Typography>
                  <Typography variant="body2" component="span">
                    Hotels face difficulties in scheduling staff shifts,
                    tracking performance effectively, and managing high turnover
                    rates. These challenges affect service quality and staff
                    morale.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} data-aos="fade-up" data-aos-delay="100">
              <Card>
                <CardContent>
                  <Typography variant="h2" component="p" className="pb-3">
                    06
                  </Typography>
                  <Typography variant="h6" component="h6">
                    Inventory Management
                  </Typography>
                  <Typography variant="body2" component="span">
                    In hotels, ineffective inventory tracking leads to food
                    waste, shortages, and operational inefficiencies. Inaccurate
                    data undermines decision-making processes, impacting overall
                    service quality and guest experience.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </section>
        {/* industry problems end */}
      </Container>
      {/* industry solutions start */}
      <section
        className="industry-solutions outer-card mb-9"
        data-aos="fade-in"
      >
        <Container maxWidth="xl">
          <Chip
            icon={<img loading="lazy" src={images.Bulb} alt="Bulb" />}
            label="Our Solutions"
            variant="outlined"
            color="success"
            className="mb-4"
            data-aos="fade-up"
          />
          <Typography
            variant="h3"
            align="center"
            component="h3"
            className="pb-7"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Our Solution to This problems
          </Typography>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  variant="h5"
                  className="pb-3"
                  component="h5"
                  data-aos="fade-up"
                >
                  Intercommunication Calls Solution
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  To address challenges with outdated LAN-based communication,
                  we developed a cloud-based device management system using
                  Grandstream for PBX and VoIP devices. This modern solution
                  ensures efficient device management, regular maintenance, and
                  seamless updates. Automated server API calls every 15 minutes,
                  real-time device monitoring, and automatic restart
                  capabilities guarantee uninterrupted service. Technician
                  alerts via email further enhance our proactive support system.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Developed cloud-based device management with Grandstream for
                    PBX and vo IP devices.
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Implemented automated server API calls every 15 minutes.
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Enabled automatic device restarts and technician alerts via
                    email.
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Ensured seamless integration updates and regular
                    maintenance.
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Designed a custom desktop application for efficient room
                    management.
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Integrated sensor data from PBX devices to enhance food
                    reporting.
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Established a secure web portal with triple-layer
                    encryption.
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Enhanced operational efficiency with centralized guest and
                    room management.
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="content">
                <Typography
                  variant="h5"
                  className="pb-3"
                  component="h5"
                  data-aos="fade-up"
                >
                  Hotel Management Application
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  For efficient hotel management, our business analysis revealed
                  gaps in room allocation, guest management, and wake-up call
                  scheduling. Addressing these needs, we designed a tailored
                  desktop application for hotel managers. This application
                  facilitates seamless room check-ins, manages wake-up calls,
                  and tracks guest check-outs. A secure web portal, featuring
                  triple-layer encryption for IP and domain management, ensures
                  only authorized access. Integration of sensor data from PBX
                  devices enhances food reporting accuracy, further optimizing
                  operational efficiency.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }} className="mb-9">
            <Grid item lg={6} xs={12}>
              <div className="content">
                <Typography
                  variant="h5"
                  data-aos="fade-up"
                  className="pb-3"
                  component="h5"
                >
                  Inventory and Staff Management
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Understanding the complexity of inventory and staff
                  management, our business analysis led to the development of
                  robust systems. Our inventory management system optimizes
                  storage and tracks room-specific items effectively. Integrated
                  staff management modules streamline shift scheduling, track
                  staff availability, and manage payroll efficiently, whether
                  based on daily or hourly rates. Detailed reporting
                  capabilities provide actionable insights, supporting informed
                  decision-making and operational excellence.
                </Typography>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Optimized storage and tracked room-specific items with
                    precision.
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Streamlined staff scheduling and payroll management.
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Provided comprehensive reporting for informed
                    decision-making.
                  </Typography>
                </li>
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Improved operational transparency and efficiency.
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container spacing={{ lg: 5, xs: 4 }}>
            <Grid item lg={6} xs={12} order={{ lg: 1, xs: 2 }}>
              <ul data-aos="fade-up">
                <li>
                  <img
                    loading="lazy"
                    src={images.OutlinedCheckmarkPrimary}
                    alt="Checkmark"
                  />
                  <Typography variant="body2" component="p">
                    Personalized customer engagements with tailored strategies.
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item lg={6} xs={12} order={{ lg: 2, xs: 1 }}>
              <div className="content">
                <Typography
                  variant="h5"
                  data-aos="fade-up"
                  className="pb-3"
                  component="h5"
                >
                  CRM Software Integration
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Recognizing the importance of customer interaction, our
                  business analysis culminated in the development of specialized
                  CRM software. This software enhances customer engagement
                  through tailored communication strategies, ensuring
                  personalized interactions and fostering customer loyalty. By
                  integrating CRM functionalities into everyday operations, we
                  empower businesses to deliver exceptional customer service and
                  drive long-term growth.
                  <br />
                  <br />
                  Each solution reflects our commitment to meticulous business
                  analysis, ensuring tailored, effective solutions that address
                  industry-specific challenges and drive operational excellence.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      {/* industry solutions end */}
      <Container maxWidth="xl">
        {/* solution impact start */}
        <section className="solution-impact mb-9" data-aos="fade-in">
          <img
            loading="lazy"
            src={images.DashBorderLeft}
            className="left"
            alt="Border"
          />
          <img
            loading="lazy"
            src={images.DashBorderRight}
            className="right"
            alt="Border"
          />
          <Typography component="h4" variant="h4" data-aos="fade-up">
            Impact after our solution in industry
          </Typography>
          <ul data-aos="fade-up">
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                By transitioning from outdated LAN-based communication to a
                cloud-based system with automated API calls and real-time
                monitoring, businesses benefit from enhanced reliability and
                efficiency. The ability to manage devices centrally and automate
                maintenance reduces downtime and operational disruptions,
                improving overall communication reliability.
              </Typography>
            </li>
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                The custom desktop application addresses critical gaps in hotel
                management, such as room allocation and guest scheduling.
                Integration of sensor data and secure web portals ensure data
                security and operational efficiency. Hotel managers experience
                streamlined operations, improved guest satisfaction through
                better service management, and enhanced security protocols.
              </Typography>
            </li>
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                The robust inventory and staff management systems optimize
                storage, streamline scheduling, and provide detailed reporting
                for informed decision-making. This leads to improved operational
                transparency, efficiency in managing resources, and better
                control over staffing costs, ultimately enhancing overall
                productivity and customer satisfaction.
              </Typography>
            </li>
            <li>
              <img
                loading="lazy"
                src={images.OutlinedCheckmarkPrimary}
                alt="Checkmark"
              />
              <Typography component="p" variant="body2">
                By integrating specialized CRM software, businesses can enhance
                customer engagement through personalized communication
                strategies. This fosters customer loyalty, improves service
                delivery, and enables businesses to capitalize on long-term
                growth opportunities by leveraging customer data effectively.
              </Typography>
            </li>
          </ul>
        </section>
        {/* solution impact end */}
      </Container>
      {/* FAQ start */}
      <FAQ faqData={faqDataPage} />
      {/* FAQ end */}

      {/* Request Proposal start */}
      <RequestProposal />
      {/* Request Proposal end */}
    </>
  );
};

export default FoodBeverages;
