import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#0487FF",
      light: "#68B7FF",
      dark: "#036CCC",
    },
    error: {
      main: "#D25149",
    },
    success: {
      main: "#3ABA66",
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },

  spacing: [0, 4, 8, 16, 24, 32, 40, 48, 64],
});

theme = createTheme(theme, {
  typography: {
    h1: {
      fontWeight: "800",
      fontSize: "96px",
      lineHeight: "120px",
      letterSpacing: "-1.5px",
      fontFamily: '"Montserrat", sans-serif',
    },
    h2: {
      fontWeight: "800",
      fontSize: "60px",
      lineHeight: "76px",
      letterSpacing: "-0.5px",
      fontFamily: '"Montserrat", sans-serif',

      [theme.breakpoints.down("xl")]: {
        fontSize: "48px",
        lineHeight: "60px",
        letterSpacing: "0px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "40px",
        lineHeight: "56px",
        letterSpacing: "0.25px",
      },
    },
    h3: {
      fontWeight: "800",
      fontSize: "48px",
      lineHeight: "60px",
      letterSpacing: "0px",
      fontFamily: '"Montserrat", sans-serif',
      [theme.breakpoints.down("lg")]: {
        fontSize: "40px",
        lineHeight: "56px",
        letterSpacing: "0.25px",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "32px",
        lineHeight: "44px",
        letterSpacing: "0.04px",
      },
    },
    h4: {
      fontWeight: "800",
      fontSize: "40px",
      lineHeight: "56px",
      letterSpacing: "0.25px",
      fontFamily: '"Montserrat", sans-serif',
      [theme.breakpoints.down("sm")]: {
        fontSize: "32px",
        lineHeight: "48px",
        letterSpacing: "0.25px",
      },
    },
    h5: {
      fontWeight: "800",
      fontSize: "32px",
      lineHeight: "48px",
      letterSpacing: "0.25px",
      fontFamily: '"Montserrat", sans-serif',

      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
        lineHeight: "32px",
        letterSpacing: "0.1px",
      },
    },
    h6: {
      fontWeight: "800",
      fontSize: "24px",
      lineHeight: "32px",
      letterSpacing: "0.1px",
      fontFamily: '"Montserrat", sans-serif',

      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
        lineHeight: "32px",
        letterSpacing: "0.15px",
      },
    },
    subtitle1: {
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "0.15px",
      fontFamily: '"Montserrat", sans-serif',
    },
    subtitle2: {
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "0.1px",
      fontFamily: '"Montserrat", sans-serif',
    },
    body1: {
      fontWeight: "400",
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "0.5px",
      fontFamily: '"Poppins", sans-serif',

      [theme.breakpoints.down("xl")]: {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "28px",
        letterSpacing: "0.25px",
      },
    },
    body2: {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "0.25px",
      fontFamily: '"Poppins", sans-serif',
    },
    button: {
      fontWeight: "800",
      fontSize: "14px",
      lineHeight: "22px",
      letterSpacing: "0.15px",
      fontFamily: '"Montserrat", sans-serif',
    },
    caption: {
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.4px",
      fontFamily: '"Poppins", sans-serif',
    },
    overline: {
      fontWeight: "400",
      fontSize: "10px",
      lineHeight: "18px",
      letterSpacing: "1.5px",
      fontFamily: '"Poppins", sans-serif',
    },
  },

  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "16px",
          paddingRight: "16px",

          [theme.breakpoints.up("sm")]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },

          [theme.breakpoints.up("md")]: {
            paddingLeft: "40px",
            paddingRight: "40px",
          },
        },

        maxWidthXl: {
          [theme.breakpoints.up("md")]: {
            maxWidth: "1072px",
          },

          [theme.breakpoints.up("xl")]: {
            maxWidth: "1280px",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          lineHeight: "28px",
          letterSpacing: "0.5px",
          textTransform: "none",
          minHeight: "56px",
          borderRadius: "30px",
          paddingLeft: "24px",
          paddingRight: "24px",
          boxShadow: "none",
          position: "relative",

          ".text": {
            position: "relative",
            zIndex: "1",
          },

          "&:hover": {
            boxShadow: "none",
            background: "#0487FF",

            "&::after": {
              transform: "translate(-50%, -50%) scale(1, 1)",
            },
          },

          "&::after": {
            content: "''",
            display: "block",
            position: "absolute",
            width: "calc(100% + 2px)",
            height: "calc(100% + 2px)",
            top: "50%",
            left: "50%",
            background: "#036CCC",
            borderRadius: "30px",
            zIndex: "0",
            transform: "translate(-50%, -50%) scale(0, 1)",
            transformOrigin: "center",
            transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
          },
        },

        sizeSmall: {
          fontSize: "14px",
          lineHeight: "22px",
          letterSpacing: "0.15px",
          minHeight: "44px",
        },

        endIcon: {
          marginLeft: "10px",
          position: "relative",
          zIndex: "1",

          img: {
            display: "block",
            height: "24px",
            width: "24px",
          },
        },

        colorInherit: {
          background: "#ffffff",
          color: "#0487FF",

          "&::after": {
            background: "#0487FF",
          },

          "&:hover": {
            color: "#ffffff",
          },
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "10px",
          borderRadius: "30px",
          transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",

          img: {
            position: "relative",
            zIndex: "1",
            transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",

            "&.active": {
              zIndex: "2",
              opacity: "0",
              visibility: "hidden",
            },
          },

          "&.outlined": {
            border: "2px solid #999999",
          },

          "&::after": {
            content: "''",
            display: "block",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "50%",
            left: "50%",
            background: "#0487ff",
            borderRadius: "30px",
            zIndex: "0",
            transform: "translate(-50%, -50%) scale(0, 1)",
            transformOrigin: "center",
            transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
          },

          "&:hover": {
            background: "transparent",
            borderColor: "#0487ff",

            img: {
              "&.active": {
                opacity: "1",
                visibility: "visible",
              },

              "&:not(.active)": {
                opacity: "0",
                visibility: "hidden",
              },
            },

            "&::after": {
              transform: "translate(-50%, -50%) scale(1, 1)",
            },
          },
        },

        sizeSmall: {
          img: {
            height: "24px",
            width: "24px",

            "&.active": {
              marginLeft: "-24px",
            },
          },
        },

        sizeMedium: {
          img: {
            height: "32px",
            width: "32px",

            "&.active": {
              marginLeft: "-32px",
            },
          },
        },

        colorInherit: {
          background: "#ffffff",
        },

        colorPrimary: {
          background: "#0487FF",

          "&::after": {
            background: "#036CCC",
          },

          "&:hover": {
            background: "#0487FF",
            borderColor: "#0487FF",

            img: {
              "&:not(.active)": {
                opacity: "1",
                visibility: "visible",
              },
            },
          },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: "16px",
          borderRadius: "12px !important",
          paddingTop: "12px",
          paddingBottom: "12px",
          border: "none",
          boxShadow: "none",

          "&::before": {
            height: "0",
          },

          "&:last-of-type": {
            marginBottom: "0",
          },
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "12px 16px 12px 24px",
          minHeight: "auto",

          "&.Mui-expanded": {
            minHeight: "auto",
          },
        },

        content: {
          margin: "0",
          fontSize: "20px",
          lineHeight: "32px",
          letterSpacing: "0.15px",
          fontWeight: "600",
          color: "#1A1A1A",
          fontFamily: '"Montserrat", sans-serif',

          "&.Mui-expanded": {
            margin: "0",
            color: "#0487FF",
          },
        },

        expandIconWrapper: {
          "&.Mui-expanded": {
            path: {
              stroke: "#0487FF",
            },
          },

          path: {
            stroke: "#4D4D4D",
          },
        },
      },
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0 16px 12px 24px",
          fontSize: "16px",
          lineHeight: "28px",
          letterSpacing: "0.25px",
          color: "#4D4D4D",
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "24px",
          boxShadow: "none",
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },

    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          a: {
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.15px",
            fontWeight: "500",
            display: "block",
            color: "#1A1A1A",

            "&:hover": {
              textDecoration: "underline",
            },
          },

          p: {
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.15px",
            fontWeight: "500",
            color: "#4D4D4D",
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          padding: "10px 24px 10px 20px",
          height: "auto",
          borderRadius: "100px",
          borderWidth: "2px",
        },

        icon: {
          marginLeft: "0",
          marginRight: "8px",
        },

        label: {
          fontSize: "16px",
          lineHeight: "28px",
          fontWeight: "700",
          letterSpacing: "0.1px",
          padding: "0",
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          fontFamily: "'Poppins', sans-serif",
          overflow: "hidden",
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontFamily: "'Poppins', sans-serif",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          paddingRight: "0",
          fontSize: "16px",
          lineHeight: "28px",
          letterSpacing: "0.25px",
          color: "#1a1a1a",

          "&.Mui-focused": {
            backgroundColor: "#ffffff",
          },

          "&.Mui-error": {
            "&::before": {
              border: "1px solid #E14B41",
              borderRadius: "6px",
              top: "0",
              transition: "none",
            },

            "&::after": {
              borderColor: "#E14B41",
            },
          },

          "&:hover": {
            backgroundColor: "#ffffff",
          },

          "&::before": {
            border: "1px solid #E1E7EC",
            borderRadius: "8px",
            top: "0",
            transition: "none",
          },

          "&::after": {
            border: "2px solid #0487FF",
            borderRadius: "8px",
            top: "0",
            transition: "none",
          },

          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            border: "1px solid #0487FF",
          },
        },

        input: {
          height: "60px",
          padding: "22px 0 0 16px",
          boxSizing: "border-box",
          color: "#1a1a1a",

          "&.MuiInputBase-inputMultiline": {
            paddingTop: "0",
            paddingLeft: "4px",
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        multiline: {
          paddingTop: "28px !important",
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#838383",
          transform: "translate(16px, 16px) scale(1);",
          fontFamily: "'Poppins', sans-serif",
          fontSize: "16px",
          lineHeight: "28px",
          letterSpacing: "0.25px",
        },

        shrink: {
          transform: "translate(16px, 8px) scale(0.85);",
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: "2px",
          marginLeft: "0",
          paddingLeft: "16px",
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.064px",
          fontFamily: "'Poppins', sans-serif",
          fontWeight: "400",
          color: "#666666",

          // "&.Mui-error": {
          //   color: "#E14B41",
          //   backgroundImage: `url(${images.Error2})`,
          //   backgroundRepeat: "no-repeat",
          //   backgroundSize: "16px",
          //   backgroundPosition: "left center",
          //   paddingLeft: "20px",
          // },
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginRight: "16px",

          ".MuiIconButton-root": {
            marginRight: "-8px",
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          flexBasis: "280px",
          maxWidth: "280px",
          position: "sticky",
          top: "120px",
          alignSelf: "flex-start",
          zIndex: "10",
          background: "#ffffff",

          [theme.breakpoints.down("xl")]: {
            top: "100px",
          },

          [theme.breakpoints.down("lg")]: {
            flexBasis: "100%",
            maxWidth: "100%",
            paddingTop: "24px",
            paddingBottom: "16px",
            top: "76px",
          },
        },

        flexContainerVertical: {
          gap: "12px",
        },

        indicator: {
          display: "none",
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textAlign: "left",
          alignItems: "flex-start",
          textTransform: "none",
          fontSize: "16px",
          lineHeight: "28px",
          letterSpacing: "0.25px",
          color: "#4D4D4D",
          fontWeight: "400",
          background: "#F2F2F2",
          borderRadius: "50px",
          paddingLeft: "24px",
          paddingRight: "24px",
          transition: "all 180ms cubic-bezier(0.4, 0, 0.2, 1)",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontFamily: "'Poppins', sans-serif",

          "&.Mui-selected": {
            fontWeight: "500",
            color: "#fff",
            background: "#0487FF",
          },

          "&:not(.Mui-selected)": {
            "&:hover": {
              color: "#0487FF",
              background: "#E6F3FF",
            },
          },
        },
      },
    },

    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: "0",
          flexBasis: "calc(100% - 280px)",
          maxWidth: "calc(100% - 280px)",

          [theme.breakpoints.down("lg")]: {
            flexBasis: "100%",
            maxWidth: "100%",
          },
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: "5px",
          borderRadius: "10px",
          background: "#E6E6E6",
          width: "200px",
        },

        bar: {
          borderRadius: "10px",
        },
      },
    },

    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          strokeLinecap: "round",
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: "#1a1a1a",
        },
      },
    },
  },
});

export default theme;
